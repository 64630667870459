/* eslint-disable react-hooks/exhaustive-deps */
import { Search, Upload } from '@styled-icons/fa-solid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getNewLoad } from '../apiCalls'
import "./Inputs.css";
import { Eye, EyeSlash, ChevronDown } from '@styled-icons/fa-solid';
import DotsLoading from '../../images/dotsLoading.gif'
import fileLoaderSvg from '../../images/pencil-outline-icon.png'

import Autocomplete from './Autocomplete';

const InputFooter = (props) => {
  return props.children;
}

const Input = (props) => {	
  const { hideLabel, label, width, className, info, value, inputRef, suffix, error, consoleFont, fullWidth, classNameInputField="", ...rest } = props;	
  return (	
    <div className={`input-outer-container ${className ? className : ''}`} style={{ width: props.fullWidth ? '77%' : ''}}>	
      {!hideLabel && (<label className={`input-label ${props.reportStyle ? '' : ' label-text'}`}>	
        <span>	
          {label}	
        </span>	
        <span style={{ flexGrow: 1 }}></span>	
        <span className="input-error-box">	
          {error ? error : "\u00A0"}	
        </span>
        &nbsp;
        <span>	
          {typeof info === "function" ? info() : (info ?? "")}	
        </span>	
      </label>)}	
      <div className="input-inner-container" 
      style={{ 
              border: props.error ? '1px red solid' : '', 
              fontFamily: props.consoleFont ? 'monospace' : '',
              color: props.consoleFont ? '#3938ac' : '',
              fontSize: props.consoleFont ? '1.1rem' : '',
    }}>	
        <input ref={inputRef} value={value} type={props.numeric ? "number" : null} className={`input-element ${classNameInputField}`} {...rest} 
        style={{
          borderTopRightRadius: suffix ? '0px' : undefined, 
          borderBottomRightRadius: suffix ? '0px' : undefined
          }} />	
        {typeof suffix === "function" ? suffix() : (suffix ?? "")}
      </div>	
    </div>	
  );	
}

const PackagesInput = (props) => {	
  const { hideLabel, label, width, className, info, value, inputRef, suffix, error, consoleFont, fullWidth, ...rest } = props;	
  return (	
    <div className={`input-outer-container ${className ? className : ''}`} style={{ width: props.fullWidth ? '77%' : ''}}>	
      {!hideLabel && (<label className="input-label label-text">	
        <span>	
          {label}	
        </span>	
        <span style={{ flexGrow: 1 }}></span>	
        <span className="input-error-box">	
          {error ? error : "\u00A0"}	
        </span>
        &nbsp;
        <span>	
          {typeof info === "function" ? info() : (info ?? "")}	
        </span>	
      </label>)}	
      <div className="input-inner-container" 
      style={{ 
              border: props.error ? '1px red solid' : '', 
              fontFamily: props.consoleFont ? 'monospace' : '',
              color: props.consoleFont ? '#3938ac' : '',
              fontSize: props.consoleFont ? '1.1rem' : '',
              backgroundColor: "rgb(247,247,247)"
    }}>	
        <input ref={inputRef} value={value} className="input-element" {...rest} 
        style={{
          borderTopRightRadius: suffix ? '0px' : undefined, 
          borderBottomRightRadius: suffix ? '0px' : undefined
          }} />	
        {typeof suffix === "function" ? suffix() : (suffix ?? "")}


        <button
              className="btn d-flex gap-2 align-items-center"
              onClick={props.showPackageModal}
            >
              <span>
              <img src={fileLoaderSvg} style={{marginBottom: "2px"}}  height="20" width="20" alt="pencil" />
              </span>
            </button>

      </div>	
    </div>	
  );	
}

const Password = (props) => {	
  const { hideLabel, label, width, className, info, inputRef, suffix, error, ...rest } = props;	
  const [type, setType] = useState('password')
  const toggleType = () => {
    if (type === 'password') setType('text')
    else if (type === 'text') setType('password')
  }
  return (	
    <div className={`input-outer-container ${className ? className : ''}`}>	
      {!hideLabel && (<label className="input-label label-text">	
        <span>	
          {label}	
        </span>	
        <span style={{ flexGrow: 1 }}></span>	
        <span className="input-error-box">	
          {error ? error : "\u00A0"}	
        </span>
        &nbsp;
        <span>	
          {typeof info === "function" ? info() : (info ?? "")}	
        </span>	
      </label>)}	
      <div className="input-inner-container" style={{ border: props.error ? '1px red solid' : '', }}>	
        <input ref={inputRef} className="input-element" type={type} {...rest} style={{borderTopRightRadius: suffix ? '0px' : undefined, borderBottomRightRadius: suffix ? '0px' : undefined}} />	
        {typeof suffix === "function" ? suffix() : (suffix ?? "")}
        {type === 'password' && <EyeSlash width="1.3rem" style={{marginRight: '10px',  color: "#00857d" }} onClick={toggleType}/>}
        {type === 'text' && <Eye width="1.9rem" style={{marginRight: '10px', color: "#00857d" }} onClick={toggleType}/> }
      </div>	
    </div>	
  );	
}

const TextArea = (props) => {	
  const { label, className, info, error, ...rest } = props;	
  return (	
    <div className={`input-outer-container ${className ? className : ''}`}>	
      <label className="input-label label-text">	
        <span>	
          {label}	
        </span>	
        <span style={{ flexGrow: 1 }}></span>	
        <span className="input-error-box">	
          {error ? error : "\u00A0"}	
        </span>	
        <span>	
          {typeof info === "function" ? info() : (info ?? "")}	
        </span>	
      </label>	
      <div className="input-inner-container" style={{ border: props.error ? '1px red solid' : '', }}>	
        <textarea className="input-element" {...rest} />	
      </div>	
    </div>	
  );	
}

const FileInput = (props) => {	
  const { hideLabel, label, className, info, error, value, tooltip, ...rest } = props;	
  return (	
    <label className={`align-self-stretch flex-grow-1 input-outer-container ${className ? className : ''}`}>	
      {!hideLabel && (<span className="input-label label-text">	
        <span>	
          {label}	
        </span>	
        <span style={{marginLeft: '10px'}}>
          {typeof tooltip === "function" ? tooltip() : (tooltip ?? "")}	
        </span>	
        <span style={{ flexGrow: 1 }}></span>	
        <span className="input-error-box">	
          {error ? error : "\u00A0"}	
        </span>
        <span>	
          {typeof info === "function" ? info() : (info ?? "")}	
        </span>	
      </span>)}	
      <div className="input-inner-container position-relative flex-grow-1" style={{ border: props.error ? '1px red solid' : '', cursor: 'pointer' }}>	
        <input type="file" {...rest} />
        <span className="input-element">
          {value?.name &&
            (value.name.length > 20
              ? value.name.slice(0, 10) + "..." + value.name.slice(-10)
              : value.name)}&nbsp;
        </span>
        <div className="position-absolute end-0 top-0 bottom-0 me-2 d-flex justify-content-center align-items-center">
        <Upload className="green-text" width="1.3em" height="1.3em" />
      </div>
      </div>
    </label>	
  );	
}

const DataList = (props) => {

  const { label, className, ...rest } = props;
  const name = props.name

  const [requestFailed, setRequestFailed] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getNewLoad(props.url)
        setData(data)
        setLoading(false)
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData(props);
  }, [])

  if (requestFailed) return <p>Failed!</p>
  if (loading) return <p name={props.name} className="selectFormRow">Loading...</p>

  return (
    <div className={`input-outer-container ${className ? className : ''}`}>
      <label className="input-label label-text">
        <span>
          {label}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {props.error ? props.error : "\u00A0"}
        </span>
      </label>
      <div className="input-inner-container" style={{ border: props.error ? '1px red solid' : '', }}>
        <input list="data" className="input-element" name={name} required />
        <datalist id="data">
          {data.map(p => <option key={p.id} value={p.data}>{p.data}</option>)}
        </datalist>
      </div>
    </div>
  )
}


const Select = (props) => {
  const { nullable, onChange, onChangeAsObject, className, label, smalltext, data, setData, options, disabledInputs, info, error, classNameInputField="", ...rest } = props;


  const handleChange = (event) => {
    setData?.(event.target.value);
    onChange?.(event);

    const selected = options.find(opt => opt?.id?.toString?.() === event.target.value);
    onChangeAsObject?.(selected);
  }

  const OptionsList = ({ options }) => {
    let result = [];
    result.push(
      <option key={0} value="">
        - Select -
      </option>
    );

    options.forEach(opt => {

        result.push(
          <option key={opt.id} value={opt.id} style={{ color: 'black' }} disabled={opt?.disabled}>
            {opt.data}
          </option>
        )

      

    });

    return result;
  }

  useEffect(() => {
    if (options.length === 1) {
      setData?.(options[0].id);
      onChange?.(options[0].id);
      onChangeAsObject?.(options[0]);
    }
  }, [options])

  return (
    <div className={`input-outer-container ${className ? className : ''}`}>
      <label className={`input-label ${props.reportStyle ? '' : ' label-text'}`}>
        <span>
          {label}
        </span>
        <span className="startInputs">
          {smalltext}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {error ? error : "\u00A0"}
        </span>
        <span>
          {typeof info === "function" ? info() : (info ?? "")}
        </span>
      </label>
      <div className="input-inner-container">
        <select
          className={`select-element ${classNameInputField}`}
          value={data ?? ""}
          onChange={handleChange}
          style={{ color: data ? '' : 'rgba(156, 163, 175)', border: props.error ? '1px red solid' : '', disabled: props.disableInputs ? true : false, }}
          {...rest}
        >
          <OptionsList options={options} />
        </select>
      </div>
    </div>
  );
}

const NeeNorSelect = (props) => {
  const { nullable, onChange, disabled, required, onChangeAsObject, className, label, smalltext, data, setData, refresh, dependencies, disabledInputs, info, error, dropdownWidth, ...rest } = props;
  const [search, setSearch] = useState();
  const [showDropdown, setShowDropdown] = useState(false);

  const [options, setOptions] = useState([])
  const [endReached, setEndReached] = useState(false)

  const [searchTimeout, setSearchTimeout] = useState();

  const scrollPosition = useRef();

  const scrollRef = useRef();

  const handleScroll = (e) => {
    const bottom = ((e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight) < 1
    if (bottom && !endReached && !scrollPosition.current) {
      scrollPosition.current = e.target.scrollTop;
      appendOptions();
      // e.target.scrollTop -= 500;
    }
  }

  const dropdownAreaRef = useRef();
  const buttonRef = useRef();
  const searchRef = useRef();

  const replaceOptions = async (value) => {
    const opts = await refresh(value ?? search, 0);
    if (opts) {
      setEndReached(opts.length < 5);
      setOptions(opts)
    }
  }

  const appendOptions = async () => {
    const opts = await refresh(search, options.length);
    if (opts) {
      setEndReached(opts.length < 5)
      setOptions((prev) => [...prev, ...opts])
      console.log(scrollPosition)
      scrollRef.current.scrollTop = scrollPosition.current;
      scrollPosition.current = null;
    }

  }

  useEffect(() => {
    replaceOptions();
  }, dependencies ?? [])

  const doSearch = (e) => {
    const value = e.target.value;
    console.log("Do search fired")
    setSearch(value);
    clearTimeout(searchTimeout);
    setSearchTimeout(
      setTimeout(() => replaceOptions(value), 1000)
      )
  }

  const dropdownStyle = useMemo(() => {
    return Object.assign(showDropdown ? {display: 'flex'} : {display: 'none'}, dropdownWidth ? {width: dropdownWidth} : {} )
  }, [showDropdown, dropdownWidth])

    const outsideClickListener = event => {
        if (!dropdownAreaRef?.current?.contains(event.target) && !buttonRef?.current?.contains(event.target)) { // or use: event.target.closest(selector) === null
          setShowDropdown(false);
          removeClickListener();
        }
    }

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    }
    const clickButton = () => {
      if (!disabled) {
        if (!showDropdown) {
          setShowDropdown(true);
          document.addEventListener('click', outsideClickListener);
        }
        else {
          setShowDropdown(false)
          removeClickListener();
        }
      }
    }

    useEffect(() => {
      if (showDropdown) {
        searchRef.current.focus();
      }
    }, [showDropdown])

  const handleChange = (data) => {
    setShowDropdown(false);
    removeClickListener();
    setData?.(data);
    onChange?.(data);

    const selected = options.find(opt => opt?.id?.toString?.() === data?.toString?.());
    onChangeAsObject?.(selected);
  }

  const OptionsList = ({ options, handleChange }) => {
    let result = [];

    options.forEach(opt => {
        result.push(
          <div onClick={() => {handleChange(opt.id)}} className='input-filterable-option' key={opt.id} value={opt.id} style={{ color: 'black' }}>
            <div>{opt.data}
            </div>
            <div style={{fontWeight: 'bold'}}>{opt.eori}
            </div>
          </div>
        )

      

    });

    return result;
  }

  const SelectOptionsList = ({ options }) => {
    let result = [];
    result.push(
      <option key={0} value="">
        - Select -
      </option>
    );

    options.forEach(opt => {

        result.push(
          <option key={opt.id} value={opt.id} style={{ color: 'black' }}>
            {opt.data}
          </option>
        )

      

    });

    return result;
  }

  const displayValue = useMemo(() => {
    const value =  {
      text: options.find(opt => opt?.id?.toString?.() === data?.toString())?.data,
      none: !options.find(opt => opt?.id?.toString?.() === data?.toString())
    }
    return value;
  }, [data, options])

  return (
    <div className={`input-outer-container ${className ? className : ''}`}>
      <label className="input-label label-text">
        <span>
          {label}
        </span>
        <span className="startInputs">
          {smalltext}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {error ? error : "\u00A0"}
        </span>
        <span>
          {typeof info === "function" ? info() : (info ?? "")}
        </span>
      </label>
      <div class="input-filterable-dropdown">
        <select ref={buttonRef} 
        required={required}
        className={"d-flex flex-row justify-content-between input-filterable-button " + (disabled ? 'input-filterable-button-disabled' : '')} style={displayValue.none ? {color: '#959595'} : {color: 'black'}}
        value={data} onMouseDown={(e) => {e.preventDefault(); clickButton();}}
        onKeyDown={(e) => {
          if (!["Tab", "Shift", "Control", "Alt"].includes(e.key)){
            e.preventDefault(); clickButton();
          }
          }}>
          <SelectOptionsList options={options} />
        </select>
        <div ref={dropdownAreaRef} className="input-filterable-dropdown-content" style={dropdownStyle}>
          <div onKeyDown={(e) => {
              if (e.key === "Escape") clickButton();
            }}
            
            className="input-filterable-search-container" >
            <Search width="1rem" style={{color: 'grey', backgroundColor: 'white', marginLeft: '4px'}}/>
            <input
            ref={searchRef} onChange={doSearch} value={search} type="text" placeholder="Search.." className="input-filterable-search" />
          </div>
          <div class="input-filterable-options-container" ref={scrollRef} onScroll={handleScroll} >
            <OptionsList options={options} handleChange={handleChange} />
            {!endReached && 
            <div className='d-flex justify-content-center'>
              <img src={DotsLoading} width="30px" height="30px" alt="Loading..." />
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

const FilterableSelect = (props) => {
  const { nullable, onChange, disabled, required, onChangeAsObject, className, label, smalltext, data, setData, options, disabledInputs, info, error, name, dropdownWidth, ...rest } = props;
  const [search, setSearch] = useState();
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownAreaRef = useRef();
  const buttonRef = useRef();
  const searchRef = useRef();

    const outsideClickListener = event => {
        if (!dropdownAreaRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) { // or use: event.target.closest(selector) === null
          setShowDropdown(false);
          setSearch('');
          removeClickListener();
        }
    }

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    }
    const clickButton = () => {
      if (!disabled) {
        if (!showDropdown) {
          setShowDropdown(true);
          document.addEventListener('click', outsideClickListener);
        }
        else {
          setShowDropdown(false)
          setSearch('');
          removeClickListener();
        }
      }
    }

    useEffect(() => {
      if (showDropdown) {
        searchRef.current.focus();
      }
    }, [showDropdown])

  const handleChange = (data) => {
    setShowDropdown(false);
    removeClickListener();
    setData?.(data);
    onChange?.(data);

    const selected = options.find(opt => opt?.id?.toString?.() === data);
    onChangeAsObject?.(selected);
  }

  const filteredOptions = useMemo(() => {
    if (!search) return options;
    return options.filter((opt) => {
      return opt.data?.toUpperCase?.().indexOf(search?.toUpperCase()) > -1
    }).sort((a, b) => {
      if (
       a.data?.toUpperCase?.()?.startsWith(search?.toUpperCase()) &&
       !b.data?.toUpperCase?.()?.startsWith(search?.toUpperCase())
      )
        return -1
      return 0;
    })
  }, [options, search])

  const OptionsList = ({ options, handleChange }) => {
    let result = [];

    options.forEach(opt => {
        result.push(
          <div onClick={() => {handleChange(opt.id)}} className='input-filterable-option' key={opt.id} value={opt.id} style={{ color: 'black' }}>
            {opt.data}
          </div>
        )

      

    });

    return result;
  }

  const SelectOptionsList = ({ options }) => {
    let result = [];
    result.push(
      <option key={0} value="">
        - Select -
      </option>
    );

    options.forEach(opt => {

        result.push(
          <option key={opt.id} value={opt.id} style={{ color: 'black' }}>
            {opt.data}
          </option>
        )

      

    });

    return result;
  }

  const displayValue = useMemo(() => {
    const value =  {
      text: options.find(opt => opt?.id?.toString?.() === data?.toString())?.data,
      none: !options.find(opt => opt?.id?.toString?.() === data?.toString())
    }
    return value;
  }, [data, options])

  return (
    <div className={`input-outer-container ${className ? className : ''}`}>
      <label className="input-label label-text">
        <span>
          {label}
        </span>
        <span className="startInputs">
          {smalltext}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {error ? error : "\u00A0"}
        </span>
        <span>
          {typeof info === "function" ? info() : (info ?? "")}
        </span>
      </label>
      <div class="input-filterable-dropdown">
        <select ref={buttonRef} 
        name={name}
        required={required}
        className={"d-flex flex-row justify-content-between input-filterable-button " + (disabled ? 'input-filterable-button-disabled' : '')} style={displayValue.none ? {color: '#959595'} : {color: 'black'}}
        value={data} onMouseDown={(e) => {e.preventDefault(); clickButton();}}
        onKeyDown={(e) => {
          if (!["Tab", "Shift", "Control", "Alt"].includes(e.key)){
            e.preventDefault(); clickButton();
          }
          }}>
          <SelectOptionsList options={options} />
        </select>
        <div ref={dropdownAreaRef} className="input-filterable-dropdown-content" style={  Object.assign(showDropdown ? {display: 'flex'} : {display: 'none'}, dropdownWidth ? {width: dropdownWidth} : {} )  }>
          <div             onKeyDown={(e) => {
              if (e.key === "Escape") clickButton();
            }}
            
            className="input-filterable-search-container" >
            <Search width="1rem" style={{color: 'grey', backgroundColor: 'white', marginLeft: '4px'}}/>
            <input
            ref={searchRef} onChange={(e) => {setSearch(e.target.value)}} value={search} type="text" placeholder="Search.." className="input-filterable-search" />
          </div>
          <div class="input-filterable-options-container" >
            <OptionsList options={filteredOptions} handleChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
}

const InputSelect = (props) => {
  const { className, label, inputData, setInputData, selectData, setSelectData, selectOptions, type } = props;

  const handleInputChange = (event) => {
    setInputData(event.target.value);
  }

  const handleSelectChange = (event) => {
    setSelectData(event.target.value);
  }

  const handleBlur = (event) => {
    if (event.target.type === 'select-one') {
      if (props.onSelectBlur) {
        props.onSelectBlur(event);
      }
    } else {
      if (props.onInputBlur) {
        props.onInputBlur(event);
      }
    }
  }

  const OptionsList = ({ options }) => {
    let result = [];
    result.push(<option key={0} value="">-</option>);

    options.forEach(opt => {
      result.push(
        <option key={opt.id} value={opt.id} style={{ color: 'black' }}>
          {opt.data}
        </option>
      )
    });

    return result;
  }

  return (
    <div className={`input-outer-container ${className ? className : ''}`}>
      <label className="input-label label-text">
        <span>
          {label}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {props.inputError ? props.inputError : "\u00A0"}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {props.selectError ? props.selectError : "\u00A0"}
        </span>

      </label>
      <div className="input-inner-container">
        {props.prefix && 
        (<span className='inputselect-prefix'>
          {props.prefix}
        </span>)
        }
        <input
          type={type}
          className="input-element inputselect-input"
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={inputData}
          style={{ border: props.inputError ? '1px red solid' : '' }} />
        <select
          className="select-element inputselect-select"
          value={selectData}
          onChange={handleSelectChange}
          onBlur={handleBlur}
          style={{ color: selectData ? '' : 'rgba(156, 163, 175)', border: props.selectError ? '1px red solid' : '' }}
        >
          <OptionsList options={selectOptions} />
        </select>
      </div>
    </div>
  );
}

const Switch = ({children, className = "", onChange, checked = false, ...rest}) => {

  const handleChange = (event) => {
    onChange({target: {name: event.target.name, value: event.target.checked}});
  }

  return (
    <label className={className}>
      {children}
      <span className="switch">
        <input type="checkbox" onChange={handleChange} checked={checked} {...rest} />
        <span className="slider"></span>
      </span>
    </label>
  )
}

const EPOFilterableSelect = (props) => {
  const { nullable, onChange, disabled, required, onChangeAsObject, className, label, smalltext, data, setData, options, disabledInputs, info, error, name, dropdownWidth, smallValue, ...rest } = props;
  const [search, setSearch] = useState();
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownAreaRef = useRef();
  const buttonRef = useRef();
  const searchRef = useRef();

    const outsideClickListener = event => {
        if (!dropdownAreaRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) { // or use: event.target.closest(selector) === null
          setShowDropdown(false);
          setSearch('');
          removeClickListener();
        }
    }

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    }
    const clickButton = () => {
      if (!disabled) {
        if (!showDropdown) {
          setShowDropdown(true);
          document.addEventListener('click', outsideClickListener);
        }
        else {
          setShowDropdown(false)
          setSearch('');
          removeClickListener();
        }
      }
    }

    useEffect(() => {
      if (showDropdown) {
        searchRef.current.focus();
      }
    }, [showDropdown])

  const handleChange = (data) => {
    setShowDropdown(false);
    removeClickListener();
    setData?.(data);
    onChange?.(data);

    const selected = options.find(opt => opt?.id?.toString?.() === data);
    onChangeAsObject?.(selected);
  }

  const filteredOptions = useMemo(() => {
    if (!search) return options;
    return options.filter((opt) => {
      return opt.data?.toUpperCase?.().indexOf(search?.toUpperCase()) > -1
    }).sort((a, b) => {
      if (
       a.data?.toUpperCase?.()?.startsWith(search?.toUpperCase()) &&
       !b.data?.toUpperCase?.()?.startsWith(search?.toUpperCase())
      )
        return -1
      return 0;
    })
  }, [options, search])

  const OptionsList = ({ options, handleChange }) => {
    let result = [];

    options.forEach(opt => {
        result.push(
          <div onClick={() => {handleChange(opt)}} className='input-filterable-option' key={opt.id} value={opt.id} style={{ color: 'black' }}>
            {opt.data}
          </div>
        )

      

    });

    return result;
  }

  const SelectOptionsList = ({ options }) => {
    let result = [];
    result.push(
      <option key={0} value="">
        - Select -
      </option>
    );

    options.forEach(opt => {

        result.push(
          <option key={opt.id} value={opt.id} style={{ color: 'black' }}>
            {opt.data}
          </option>
        )

      

    });

    return result;
  }

  const displayValue = useMemo(() => {
    const value =  {
      text: options.find(opt => opt?.id?.toString?.() === data?.toString())?.data,
      none: !options.find(opt => opt?.id?.toString?.() === data?.toString())
    }
    return value;
  }, [data, options])

  return (
    <div className={`input-outer-container ${className ? className : ''}`}>
      <label className="input-label label-text">
        <span>
          {label}
        </span>
        <span className="startInputs">
          {smalltext}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {error ? error : "\u00A0"}
        </span>
        <span>
          {typeof info === "function" ? info() : (info ?? "")}
        </span>
      </label>
      <div class="input-filterable-dropdown">
        <select ref={buttonRef} 
        name={name}
        required={required}
        className={"d-flex flex-row justify-content-between " + (smallValue ? 'input-filterable-button-smallText ' : 'input-filterable-button ') + (disabled ? 'input-filterable-button-disabled' : '')} style={displayValue.none ? {color: '#959595'} : {color: 'black'}}
        value={data} onMouseDown={(e) => {e.preventDefault(); clickButton();}}
        onKeyDown={(e) => {
          if (!["Tab", "Shift", "Control", "Alt"].includes(e.key)){
            e.preventDefault(); clickButton();
          }
          }}>
          <SelectOptionsList options={options} />
        </select>
        <div ref={dropdownAreaRef} className="input-filterable-dropdown-content" style={  Object.assign(showDropdown ? {display: 'flex'} : {display: 'none'}, dropdownWidth ? {width: dropdownWidth} : {} )  }>
          <div             onKeyDown={(e) => {
              if (e.key === "Escape") clickButton();
            }}
            
            className="input-filterable-search-container" >
            <Search width="1rem" style={{color: 'grey', backgroundColor: 'white', marginLeft: '4px'}}/>
            <input
            ref={searchRef} onChange={(e) => {setSearch(e.target.value)}} value={search} type="text" placeholder="Search.." className="input-filterable-search" />
          </div>
          <div class="input-filterable-options-container" >
            <OptionsList options={filteredOptions} handleChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
}


export { InputFooter, Input, PackagesInput, Select, FilterableSelect, NeeNorSelect, InputSelect, Autocomplete, Switch, DataList, TextArea, FileInput, Password, EPOFilterableSelect };