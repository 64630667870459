/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import "../main.css";
import "./loaddetails.css";
import { getListLoad, postListLoad, postSDISubmit, postSFDSubmit, postC21Submit } from "../apiCalls";
import DataTable from "../DataTable";
import { StoreContext } from "../Store";
import { useNavigate } from "react-router-dom";
import "../Grids/gridEdit.css";
import { useDataTableHandle } from '../DataTable/DataTable';
import { OverlayTrigger, Popover, PopoverContent, PopoverTitle } from 'react-bootstrap';
import isoDateConverter from "../isoDateConverter";
import ArrivalConfirmation from '../ArrivalConfirmation';
import { update } from 'lodash';
import { DeclarationsButton } from '../DeclarationsButton';

const LoadStatus = (props) => {
  const [requestFailed, setRequestFailed] = useState(false);
  const loadNumber = props.invoicenumber
  const store = useContext(StoreContext);
  const navigate = useNavigate()

  const [showArrivalModal, setShowArrivalModal] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [error, setError] = useState();
  const headerData = props.headerData;

  useEffect(() => {
    async function fetchData() {
      try {
        refreshGrid();
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [])

  const refreshGrid = async () => {
    try {
      const data = await getListLoad('status', loadNumber)
      store.gridItems[1](data)
    } catch (err) {
      setRequestFailed(true);
    }
  }

  const handleOpenModal = (item) => {
    setModalItem(item);
    setShowArrivalModal(true);
  }

  const handleSubmit = async () => {
    setError();
    const sdi = dataHandle.current.modifiedData.find((row) => {
      if (row["Additional Information"] === "SDI") return true
      return false;
    })
    if (sdi) {
      if (sdi.Status === "Submitted" && !!sdi["System Ref No."]) {
        setError("Please request SDI (see button) before submitting a system reference number")
        return;
      }
    }

    const sfd = dataHandle.current.modifiedData.find((row) => {
      if (row["Additional Information"] === "SFD") return true
      return false;
    })

    if (sfd) {
      if (sfd.Status === "Submitted" && !!sfd["System Ref No."]) {
        setError("Please request an SFD (see button) before submitting MUCR/UCN reference number")
        return;
      }
    }

    const C21 = dataHandle.current.modifiedData.find((row) => {
      if (row["Additional Information"] === "C21") return true
      return false;
    })

    if (C21) {
      if (C21.Status === "Submitted" && !!C21["System Ref No."]) {
        setError("Please request an C21 (see button) before submitting MUCR/UCN reference number")
        return;
      }
    }

    const updatedCells = [];
    let i = 0;
    for (const row of dataHandle.current.modifiedData) {
      const system = row["System"];
      const refNo = row["System Ref No."];

      updatedCells.push({
        colname: "System",
        rowid: row.id,
        staterow: i++,
        value: system
      })

      updatedCells.push({
        colname: "SystemRefNo",
        rowid: row.id,
        staterow: i++,
        value: refNo
      })
    }
    const invLinked = dataHandle.current.modifiedData.find((row) => {
      return row["Form type"] === 'Inventory linked release'
    })
    if (invLinked) {
      updatedCells.push({
        colname: "PortArrived",
        rowid: invLinked.id,
        staterow: i++,
        value: (invLinked.PortArrived?.id ? invLinked.PortArrived.id : invLinked.PortArrived) ?? null
      })
    }

    try {
      const data = await postListLoad(updatedCells, 'status')
      if (data[0].completed === 'Updated') {
        store.saved[1](data);
        refreshGrid();
      }
    } catch (err) {
      console.log(err);
      alert('Error saving to DB')
    }
  }

  const handleDocSubmit = async () => {
    const sfdRequest = {
      id: loadNumber
    };
    try {
      await postSFDSubmit(sfdRequest);
      refreshGrid();
    } catch (err) {
      console.log(err);
      alert("Error sending SFD");
    }
  }

  const handleC21Submit = async () => {
    const c21Request = {
      id: loadNumber
    };
    try {
      await postC21Submit(c21Request);
      refreshGrid();
    } catch (err) {
      console.log(err);
      alert("Error sending C21");
    }
  }

  const handleModalSubmit = async () => {
    const sdiRequest = {
      id: loadNumber,
      date: store.portArrivalDate[0],
      time: store.portArrivalTime[0]
    };
    try {
      await postSDISubmit(sdiRequest);
      refreshGrid();
    } catch (err) {
      console.log(err);
      alert("Error sending SDI");
    }
  }

  const dataHandle = useDataTableHandle();

  if (requestFailed) return <p>Failed!</p>
  return (
    <div className="floating-box-newload loaditems">
    <div className='d-flex flex-row justify-content-between'>
      <div className='floating-box-header-newload loads '>
     <span>Load Status</span>
     </div>
       <div className="d-flex flex-column gap-2 justify-content-between align-items-end mb-4">
            <div className="d-flex flex-row">
              <div className='d-flex flex-column cabie-company-info' style={{marginRight: '10px'}} >
               <span className="cci-header"> Portal Ref: </span>
               {headerData.companyName && <span className="cci-header">Company Name: </span>}
               {headerData.eoriNumber && <span className="cci-header">EORI Number: </span>}
              </div>

              <div className='d-flex flex-column  cabie-company-info'>
              <span className='pad-out'>{headerData.portalRef}</span>
              {headerData.companyName && <span className='pad-out'> {headerData.companyName.slice(0,50)} </span>}
              {headerData.eoriNumber && <span className='pad-out'> {headerData.eoriNumber} </span>}
            </div>
          </div>
        </div>

      </div>

      <DataTable data={store.gridItems[0]} className="table-striped table-bordered" editable handle={dataHandle}>
        <DataTable.Headers className="bg-white" style={{ fontSize: '13px' }} />
        <DataTable.Column colKey="Form type" header="Transaction Type" />
        <DataTable.Column colKey="Country/Port" header="Location" />
        <DataTable.Column colKey="Additional Information" header="Additional Information" maxWidth='26ch' />
        <DataTable.Column colKey="System" header="Customs software" className='p-2 edit-cell' maxWidth='17ch' editable={(item) => { return !item.locked }} />
        <DataTable.Column colKey="System Ref No." header="System Reference" className='p-2 edit-cell' maxWidth='17ch' editable={(item) => { return !item.locked }} />
        <DataTable.Column colKey="PortArrived" header="Vessel Arrived" locked={(item) => { return item.locked }} editable={(item) => { return (item["Form type"] === "Inventory linked release") }} >
          <DataTable.Select options={[{ id: 1, data: "No" }, { id: 2, data: "Yes" }]} optionIdKey="id" optionDisplayKey='data' />
        </DataTable.Column>
        <DataTable.Column colKey="Status" header="Status">{item => <StatusIcon data={item.Status} created={item.createdDate} error={item.errorDate} ros={item.rosDate} ack={item.ackDate} 
        submitUser={item.submitUser} refNumber={item.refNumberUser} refTime={item.refTime} submitTime={item.submitTime} refDepartment={item.refDepartment} 
        submitDepartment={item.submitDepartment} invVesselDep={item.invVesselDep} invVesselTime={item.invVesselTime} invVesselUser={item.invVesselUser} remoteSystemUser={item.remoteSystemUser} 
        remoteSystemDep={item.remoteSystemDep} remoteSystemTime={item.remoteSystemTime} createdUser={item.createdUser} createdRole={item.createdRole} />}
        </DataTable.Column>
        <DataTable.Column header="Submit" style={{ whiteSpace: 'nowrap', width: '1px' }}>
          {(item) => (
            
            <DeclarationsButton 
            C21={item.C21}
            SFD={item.SFD}
            SDI={item.SDI}
            submitUser={item.submitUser}
            refNumber={item.refNumberUser}
            InventoryLinked={item.InventoryLinked}
            Status={item.Status}
            formSubmitted={item.formSubmitted}
            handleOpenModal={() => handleOpenModal(item)} 
            handleDocSubmit={() => handleDocSubmit(item)}
            handleC21Submit={() => handleC21Submit(item)}  />
          )}
        </DataTable.Column>
      </DataTable>
      <div className="d-flex flex-row py-3 px-3 mt-4 gap-3 border-top">
        <button className="stepper-button yellow" onClick={props.prevPage}>Items</button>
        <div className="flex-grow-1" />
        <div className="">
          {error && (
            <div className="alert-danger px-3 py-2 w-100 " style={{ borderRadius: '10px', textAlign: "center" }}>{error}</div>
          )}
        </div>
        <div className="flex-grow-1" />
        <button className="stepper-button green" onClick={() => dataHandle.current.reset()}>Undo</button>
        <button className="stepper-button red" onClick={handleSubmit}>Update</button>

      </div>

      <ArrivalConfirmation
        header="Port Arrival"
        size="md"
        showButtons={true}
        animation={false}
        show={showArrivalModal}
        onHide={() => { setShowArrivalModal(false); setModalItem(null) }}
        subTitle={'Please select the port arrival date/time as this is required to produce a Supplementary Declaration.'}
        onConfirm={handleModalSubmit}
        item={modalItem}
      />
    </div>
  )
}


const StatusIcon = ({ data, created, error, ros, ack, submitUser, refNumber, refTime, submitTime, refDepartment, 
  submitDepartment, invVesselUser, invVesselDep, invVesselTime, remoteSystemUser, remoteSystemDep, remoteSystemTime, createdUser , createdRole}) => {

  const dates = useMemo(() => {
    return (
      <>
        {created && <p className="tooltip-text"> <span className="cci-header">Submitted: </span>{isoDateConverter(created)}</p>}
        {ack && <p className="tooltip-text"> <span className="cci-header">In Progress: </span>{isoDateConverter(ack)}</p>}
        {ros && <p className="tooltip-text"> <span className="cci-header">Completed: </span> {isoDateConverter(ros)}</p>}
        {error && <p className="tooltip-text"><span className="cci-header">Failed: </span> {isoDateConverter(error)}</p>}
      </>
    )
  }, [created, error, ros, ack])

  return (<OverlayTrigger
    placement="top"
    trigger={["hover", "focus"]}
    overlay={
      <Popover style={{ minWidth: "17.5rem" }}>
        <PopoverTitle>
          <h5 style={{ margin: 0 }}>Item Status</h5>
        </PopoverTitle>
        <PopoverContent>
          <div className="auditStatus">
            <span>
              <span className={`position-tips statusIcon ${data}`}></span>
            </span>
            <div className="statusTimeStamp">
              <span className="timeStampError">
                {data}
              </span>
              {dates}
              {createdUser && (<p>
                <span className="cci-header">Created by: </span>
                <span>{createdUser}, {createdRole}, {isoDateConverter(created)}</span>
              </p>)}
              {submitUser && (<p>
                <span className="cci-header">Form submission: </span>
                <span>{submitUser}, {submitDepartment}, {isoDateConverter(submitTime)}</span>
              </p>)}
             
              {refNumber && (<p>
                <span className="cci-header">System reference entry: </span>
                <span>{refNumber}, {refDepartment}, {isoDateConverter(refTime)}</span>
              </p>)}

              {invVesselUser && (<p>
                <span className="cci-header">Vessel update: </span>
                <span>{invVesselUser}, {invVesselDep}, {isoDateConverter(invVesselTime)}</span>
              </p>)}
              {remoteSystemUser && (<p>
                <span className="cci-header">Customs software update: </span>
                <span>{remoteSystemUser}, {remoteSystemDep}, {isoDateConverter(remoteSystemTime)}</span>
              </p>)}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    }
  >
    <div className={`positions ${data}`}></div>
  </OverlayTrigger>)
}
export default LoadStatus;

