import React, { useState, useEffect } from "react";
import BusinessPartnersList from "./BusinessPartnersList";
import BusinessPartnerCard from "./BusinessPartnerCard";

const BusinessPartners = () => {
  const [requestFailed, setRequestFailed] = useState("");
  const [selectedNeenor, setSelectedNeenor] = useState(false);
  const [editCreate, setEditCreate] = useState();

  const updateNeenor = (key, value) => {
    setSelectedNeenor((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const [neeNors, setNeeNors] = useState([
  ]);


  // useEffect(() => {
  //   if (selectedNeenor) console.log("selectedNeenor", selectedNeenor)
  // }, [selectedNeenor]);

  if (requestFailed) return <p>Failed!</p>;
  console.log('selected neenor', selectedNeenor)
  console.log('selected neenor', !selectedNeenor)
  return (
    <>
    {selectedNeenor === false ? 
      <>
      <BusinessPartnersList
        neeNors={neeNors}
        setNeeNor={setSelectedNeenor}
        setNeeNors={setNeeNors}
        setEditCreate={setEditCreate}
        origin="bp"
      />
      </>
      : 
      <BusinessPartnerCard id={selectedNeenor} back={() => {setSelectedNeenor(false)}} editCreate={editCreate} setNeeNor={setSelectedNeenor} />
      }
    </>
  );
};

export default BusinessPartners;
