
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {useContext} from "react";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Main from "./components/Main";
import Home from "./components/Home";
import Team from "./components/Team";
import Neenor from "./components/neeNor/Neenor";
import Products from "./components/products/Products";
import Newload from "./components/Newload/Newload.js";
import ProductDetails from "./components/products/ProductDetails";
import Invoices from "./components/Invoices";
import InvoiceDetails from './components/InvoiceDetails';
import ReportDetails from './components/ReportDetails';
import UserDetails from './components/UserDetails';
import Register from './components/Registration/Register';
import UserProfile from './components/CompanySetup/UserProfile';
import ListLoads from './components/ListLoads'
import Loads from './components/LoadList/Loads.js';
import LoadDetails from './components/LoadList/LoadDetails.js';
import AddUser from './components/AddUser'
import "./components/main.css";
import "./App.css";
//import Navbar from "./components/Navbar";
//import Footer from "./components/Footer";
import UserAdmin from './components/UserAdmin'
import UserAdminAudit from "./components/UserAdminAudit";
import TeamDetails from './components/TeamDetails'
import NeeNorDetails from './components/neeNor/NeeNorDetails'
import 'bootstrap/dist/css/bootstrap.min.css'
import NewLoadFileUpload from "./components/Newload/NewLoadFileUpload.js";
import FileUpload from './components/fileUpload/FileUpload';
import ForgotPassword from './components/ForgotPassword';
import ConfirmReset from './components/ConfirmReset';
import Activated from './components/Activated';
import PasswordResetSent from './components/PasswordResetSent';
import { ErrorBoundary } from "react-error-boundary";

import AdditionalFileUpload from './components/AdditionalFileUpload/AdditionalFileUploadContainer'
import AdditionalFileDisplay from './components/AdditionalFileUpload/AdditionalFileDisplay'
import DocusignSubmitted from "./components/docusign/DocusignSubmitted";
import DocusignReopen from "./components/docusign/DocusignReopen";

import Maintenance from "./components/refData/Maintenance"
import GenericErrorFallback from "./components/Errors/GenericErrorFallback";
//import EnvBanner from "./components/EnvBanner";
import AllClients from "./components/Clients/AllClients";
import EpoListLoads from "./components/EpoListLoads/EpoListLoads";
import EpoJobDetails from "./components/EpoListLoads/Details/EpoJobDetails";
import EpoApplications from "./components/Registration/Sign Up/EpoApplications"
import EpoApplicationDetails from "./components/Registration/Sign Up/EpoApplicationDetails"
import EpoLoads from "./components/Epo/EpoLoads";
import EpoExports from "./components/Epo/EpoExports";
import AdditionalEPOFileUpload from "./components/AdditionalEPOFileUpload/AdditionalEPOFileUploadContainer"
import Search from "./components/DataValidation/Search";
import { SecurityProvider, PrivateRoute } from "./components/Security/security";

// import WlpLSP from "./components/WLP/WlpLSP";
import WlpTrader from "./components/WLP/WlpTrader";
import WlpLoads from "./components/WLP/WlpLoads";

import OnBoarding from "./components/OnBoarding/Registration";
import ReportsAdmin from "./components/Reports/ReportsAdmin";
// import ReportsAdminA from "./components/Reports/ReportsAdminA";
import ReportsResults from "./components/Reports/ReportsResults";
// import ShortCodeUpload from "./components/Reports/ShortCodeUpload";
import ReportsList from "./components/Reports/ReportsList.js";

import EPODownloadView from "./components/AdditionalEPOFileUpload/EPODownloadView"
import GoogleAnalyticsProvider from "./components/GoogleAnalytics"
import MessagingProvider from './components/MessagingProvider'

import UserAdminEPO from "./components/UserAdminEPO.js";

import UserAdminCommcodes from "./components/UserAdminCommcodes.js";
import { useState, useEffect } from 'react';
import { StoreContext } from "./components/Store";
import Layout from "./Layout";
import BusinessPartners from './components/BusinessPartners/BusinessPartners.js'
import BusinessPartnerCard from "./components/BusinessPartners/BusinessPartnerCard.js";

const App = () => {
  const store = useContext(StoreContext);

     useEffect(() => {

        try { switch (window.location.host) { 

                  //-- Local dev

                  case "localhost": 
                  import('./App-cb.css');         
                  break


                  // case "localhost": 
                  //   import('./App-tk.css');
                  //   store.isWLP[1](true);
                  //   store.wlpName[1]('TEK Freight');
                  // break
 
                  // ==============  D E V =====================================
                  
                  // DEV - CABIE
                   case "dev.eoricustomtech.co.uk":
                    import('./App-cb.css');
                  break
                  

                  // DEV - WLP - updated to TEK Freight
                  case "wdev.eoricustomtech.co.uk":
                    import('./App-tk.css');
                    store.isWLP[1](true);
                    store.wlpName[1]('TEK Freight');
                  break

                  // ==============  U A T  =====================================

                  // UAT - WLP - updated to TEK Freight
                    case "floraline.azurewebsites.net": // uat
                    import('./App-tk.css');
                    store.isWLP[1](true);
                    store.wlpName[1]('TEK Freight');
                    break  
                    
                    // UAT - CABIE
                  case "eori-portal-test.azurewebsites.net": // uat
                  import('./App-cb.css');   
                  break  

                  //====================  P R O D  =================================

                  // floralinc
                  // case "cabie-preprod.azurewebsites.net":
                  case "floralinc.cabie.uk": // prod
                    import('./App-fl.css');  
                    store.stepTitleColor[1]("dark"); 
                    store.isWLP[1](true);
                    store.wlpName[1]('FloraLinc');
                  break

                  // TEK Freight
                  case "tekfreight.cabie.uk": // TODO: 
                    import('./App-tk.css');
                    store.isWLP[1](true);
                    store.wlpName[1]('TEK Freight');
                  break

                  // zelir
                  // case "zelir.eoricustomtech.co.uk": // uat
                  case "zelir.cabie.uk": // prod
                    import('./App-zr.css');
                    store.isWLP[1](true);   
                    store.wlpName[1]('ZELIR LOGISTICS');
                  break

                  // LGL
                  case "lglworldwide.cabie.uk": // prod
                    import('./App-lgl.css');   
                    store.stepTitleColor[1]("dark");
                    store.isWLP[1](true);
                    store.wlpName[1]('LGL WorldWide Logistics');
                  break

                  // chillchain
                  case "chillchain.cabie.uk": // prod
                    import('./App-cc.css');   
                    store.isWLP[1](true);
                    store.wlpName[1]('Chill-Chain');
                  break

                   // capital transport
                   case "capitaltransport.cabie.uk": // prod
                     import('./App-ct.css');  
                     store.stepTitleColor[1]("dark"); 
                     store.isWLP[1](true);
                     store.wlpName[1]('Capital Transport');
                   break

                  // Theunisse-expeditie
                   case "theunisse-expeditie.cabie.uk":
                    import('./App-dl.css');   
                    store.isWLP[1](true);
                    store.wlpName[1]('Theunisse Expedite');
                   break

                    // Ponsonby
                     case "ponsonby.cabie.uk":
                      import('./App-pp.css');   
                      store.isWLP[1](true);
                      store.wlpName[1]('Paul Ponsonby');
                     break

                      // Alcaline
                      case "alcaline.cabie.uk":
                        import('./App-al.css');    
                        store.isWLP[1](true);   
                        store.wlpName[1]('Alcaline');  
                        store.stepTitleColor[1]("dark"); 
                     break;

                     // C-3PL
                     case "complete3pl.cabie.uk": 
                      import('./App-cp.css');
                      store.isWLP[1](true);
                      store.wlpName[1]('C-3PL');
                      store.stepTitleColor[1]("dark"); 
                     break

                     // Oakland
                     case "oakland-international.cabie.uk":
                      import('./App-oa.css');
                      store.isWLP[1](true);
                      store.wlpName[1]('Oakland International');
                    break;

                  // Non specified URLs default to CABIE

                  default:
                    import('./App-cb.css');              
                  break

              }
        } catch (err) {
      } 
    }, [])

  return (
    <Router>
      <SecurityProvider>
        <MessagingProvider>
          <GoogleAnalyticsProvider>
            <ErrorBoundary FallbackComponent={GenericErrorFallback}>
              <Routes>
                <Route element={<Layout/>}>
                    <Route exact path="/" element={<Home/>} auth={false} />
                    <Route path="/Register" element={<Register/>} auth={false} />
                    <Route path="/Login" element={<Login/>} auth={false} />
                    <Route path="/forgotPassword" element={<ForgotPassword/>} auth={false} />
                    <Route path="/confirmReset/:token" element={<ConfirmReset/>} auth={false} />
                    <Route path="/Activated/:token" element={<Activated/>} auth={false} />
                    <Route path="/PasswordResetSent/" element={<PasswordResetSent/>} auth={false} />
                    <Route path='/DocusignSubmitted' element={<DocusignSubmitted/>} auth={false} />
                    <Route path='/DocusignReopen' element={<DocusignReopen/>} auth={false} />
                    <Route exact path="/" element={<PrivateRoute/>}>
                    <Route path='/main' element={<Main/>} />
                    <Route path='/CustomerManagement' element={<AllClients/>} />
                    <Route path='/DataValidation' element={<Search/>} />
                    <Route path='/team' element={<Team/>} />
                    <Route path='/teamdetails' element={<TeamDetails/>} />
                    <Route path='/neenor' element={<Neenor/>} />
                    <Route path='/NeeNorDetails' element={<NeeNorDetails/>} />
                    <Route path='/products' element={<Products/>} />
                    <Route path='/ProductDetails' element={<ProductDetails/>} />
                    <Route path='/Newload/:id?' element={<Newload/>} />
                    <Route path='/NewLoadFileUpload' element={<NewLoadFileUpload/>} />
                    <Route path='/ListLoads' element={<ListLoads/>} />
                    <Route path='/LoadDetails' element={<LoadDetails/>} />
                    <Route path='/Loads/:id' element={<Loads/>} />
                    <Route path='/Invoices' element={<Invoices/>} />
                    <Route path='/InvoiceDetails' element={<InvoiceDetails/>} />
                    <Route path='/ReportDetails' element={<ReportDetails/>} />
                    <Route path='/UserDetails/new' element={<UserDetails/>} />
                    <Route path='/UserDetails/:id' element={<UserDetails/>} />
                    <Route path='/Userprofile/:currentTab' element={<UserProfile/>} />
                    <Route path='/Userprofile' element={<UserProfile/>} />
                    <Route path='/UserAdmin' element={<UserAdmin/>} />
                    <Route path='/UserAdminEPO' element={<UserAdminEPO/>} />
                    <Route path='/UserAdminAudit' element={<UserAdminAudit/>} />
                    <Route path='/AddUser' element={<AddUser/>} />
                    <Route path='/FileUpload' element={<FileUpload/>} />
                    <Route path='/Logout' element={<Logout/>} />
                    <Route path='/AdditionalFileUpload' element={<AdditionalFileUpload/>} />
                    <Route path='/AdditionalFileDisplay' element={<AdditionalFileDisplay/>} />
                    <Route path='/Maintenance' element={<Maintenance/>} />
                    <Route path="/EpoListLoads" element={<EpoListLoads/>} />
                    <Route path="/EpoJobDetails/:id" element={<EpoJobDetails/>} />
                    <Route path="/EpoApplications" element={<EpoApplications/>} />
                    <Route path="/EpoApplicationDetails/:id" element={<EpoApplicationDetails/>} />
                    <Route path="/AdditionalEPOFileUpload/:id" element={<AdditionalEPOFileUpload/>} />
                    <Route path='/EPODownloads' element={<EPODownloadView/>} />
                    <Route path="/NewEpo" element={<EpoLoads/>} />
                    <Route path="/EpoExports" element={<EpoExports/>} />
                    <Route path='/WlpTrader' element={<WlpTrader/>} />
                    <Route path='/WlpLoads' element={<WlpLoads/>} />
                    <Route path='/OnBoarding' element={<OnBoarding/>} />
                    <Route path='/ReportsAdmin/:reportName?' element={<ReportsAdmin/>} />
                    <Route path='/ReportsResults/:endPoint?/:reportName?/:paramCombination?' element={<ReportsResults/>} />
                    <Route path='/ReportsResults/:endPoint?/:reportName?/:paramCombination?/:startDate?/:endDate?' element={<ReportsResults/>} />
                    <Route path='/ReportsResults/:endPoint?/:reportName?/:paramCombination?/:startDate?/:endDate?/:customer?' element={<ReportsResults/>} />
                    <Route path='/ReportsResults/:endPoint?/:reportName?/:paramCombination?/:startDate?/:endDate?/:startDate2?/:endDate2?' element={<ReportsResults/>} />
                    <Route path='/ReportsResults/:endPoint?/:reportName?/:paramCombination?/:startDate?/:endDate?/:startDate2?/:endDate2?/:customer?' element={<ReportsResults/>} />
                    <Route path='/ReportsList' element={<ReportsList/>} />  
                    <Route path='/UserAdminCommcodes' element={<UserAdminCommcodes/>} /> 
                    <Route path='/BusinessPartners' element={<BusinessPartners/>} /> 
                    <Route path="/businesspartner/:id" element={<BusinessPartnerCard/>} />
                    </Route>
                  <Route path="*" element={<Login/>} />
                </Route>
              </Routes>
            </ErrorBoundary>
          </GoogleAnalyticsProvider>
        </MessagingProvider>
      </SecurityProvider>
    </Router>
  );
}

export default App;
 

