import React, { useContext } from 'react';
import information from '../../../images/information.svg'
import { StoreContext } from '../../Store';

const OptionDirection = () => {

    const store = useContext(StoreContext);

    return (
        <div className="semi-fluid-container container-xl file-upload" style={{ maxWidth: '700px', marginTop: '4rem' }}>
            <div className='floating-box-newload'>
                <div className='floating-box-header-newload'>
                    <span>Items entry</span>
                </div>
                <div id="load-info-form" className='fileloader-header'>
                    <div className="inputs-group">
                        <span className='more-info'><img src={information} height="" width="25" alt="info" />&nbsp;&nbsp;
                            Select how you would like to create the shipment items for this load.
                            <br />
                            <br />
                            Paste: Data can be pasted into a preformatted table.
                            <br />
                            <br />
                            Create: Manually enter your shipment item data.
                        </span>
                    </div>
                    <div className="inputs-group">
                        <span className='more-info'>Copy and paste from your data.</span>
                        <button className="blue-button-newload file-loader-btn-submit" onClick={() => store.pasteNewLoad[1](true)}>Paste</button>
                        <span className='more-info'>Create a manual worksheet.</span>
                        <button className="blue-button-newload file-loader-btn-submit" onClick={() => store.pasteNewLoad[1](false)}>Create</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OptionDirection;