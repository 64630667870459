import {Row, Col} from 'react-bootstrap'
import {Input} from './Input/Input'

function GridTotals({grossKG, netKG, itemCount, value, rows, other=[]}) {
    function numberWithCommas(x, toFixed) {
        let floatValue = isNaN(parseFloat(x)) ? 0 : parseFloat(x);
        if (toFixed)
            floatValue = floatValue.toFixed(2);
        return floatValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

   const grossKGformatted = numberWithCommas(grossKG, true);
   const netKGformatted = numberWithCommas(netKG, true);
   const itemCountformatted = numberWithCommas(itemCount, false);
   const valueFormatted = numberWithCommas(value, true);

  return (
    <div className="d-flex flex-column" style={{ marginTop: "2rem", marginBottom: "1rem" }} >
    <Row>
        {
        other.map((item) => {
            return (
                <Col>
                {item}
                </Col>
            )
        })
        }
      {grossKG !== undefined && (<Col>
        <Input disabled label="Gross Mass (kg)" value={grossKGformatted} />
      </Col>
      )}
      {netKG !== undefined && (<Col>
        <Input disabled label="Net Mass (kg)" value={netKGformatted} />
      </Col>
      )}
      {itemCount !== undefined && (<Col>
          <Input disabled label="Number of Packages" value={itemCountformatted} />
        </Col>
        )}
      {value !== undefined && (<Col>
        <Input disabled label="Value" value={valueFormatted} />
      </Col>
      )}
      {rows !== undefined && (<Col>
        <Input disabled label="Item Rows" value={rows} />
      </Col>
      )}
    </Row>
    </div>
  )
}

export default GridTotals