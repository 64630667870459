import { createContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Stepper, { useStepper } from "../Stepper";
import BPCompanyDetails from "./BPCompanyDetails";
import BPAddress from "./BPAddress";
import BPCustomsAndCompliance from "./BPCustomsAndCompliance";
import BPCabie from "./BPCabie";
import BPBillingDetails from "./BPBillingDetails";
import BPModal from "./BPModal";
import BPWarningModal from "./BPWarningModal";
import BPDocuments from "./BPDocuments";

import {
  fetchBusinessPartner,
  saveBusinessPartner,
  fetchBusinessPartnerDropdownValues,
} from "./BusinessPartnerController";

//CSS files
import "./BusinessPartnerCard.css";
import BPUploadDocumentModal from "./BPUploadDocumentModal";
import BPDocumentListModal from "./BPDocumentsListModal";

//creating a context for the business partner page
export const BusinessPartnerContext = createContext();

const BusinessPartnerCard = ({ id, back, setNeeNor }) => {
  //States
  const [currentStep, setCurrentStep] = useState('BPcompanydetails'); //set the current step to be the first tab in the stepper
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  //set BPData and localBPData to be an empty object. They are going to manage what we display on the page and what we save to the database.
  const [BPData, setBPData] = useState({});
  const [localBPData, setLocalBPData] = useState({});

  const [modalShow, setModalShow] = useState(false);
  const [dropdownValues, setDropdownValues] = useState({});
  const [errors, setErrors] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [eoriNumber, setEoriNumber] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [tabValidation, setTabValidation] = useState({});
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [onConfirmFunction, setOnConfirmFunction] = useState(null);
  const [showDocumentsListModal, setShowDocumentsListModal] = useState(false);

  //========================================

  //useEffects
  useEffect(() => {
    (async () => {
      fetchTabInfo();
    })();
  }, []);

  useEffect(() => {
    console.log('step has changed', currentStep)
  }, [currentStep]);

  useEffect(() => {
    fetchTabInfo();
  }, [id]);

  useEffect(() => {
    if (onConfirmFunction) {
      setShowWarningModal(true);
    }
    else{
      setShowWarningModal(false);
    }
  }, [onConfirmFunction]);
  //==============================================

  //variable to control the stepper. This variable will map each step in the stepper to the tables that are in that step
  const stepperTables = {
    BPcompanydetails: ["Business Partner", "Company", "Main Contact Details"],
    BPaddress: ["Address"],
    BPCabie: ['Trader Data'],
    BPBillingDetails: ["Company Payment Details"],
    BPCustomsAndCompliance: [
      "Authorisation Documents",
      "Customs Authorisations",
    ],
    BPDocuments: ['Documents'],
  };

  //mapping for the column names. This will be used to map the column names from the database when doing the get to what we need when updating the data
  const columnNames = {
    "Position in Company": "companyPosition",
    "Business Phone Number": "phoneNumber",
    Type: "businessType",
    "CABIE Status": "CABIEStatus",
    "Secondary Status": "secondaryStatus",
    "Company Name": "companyName",
    "Legal Name of Entity": "entityLegalName",
    Jurisdiction: "jurisdiction",
    "Trader EORI Number": "eoriNumber",
    "VAT Number": "companyVatNumber",
    "Company Registration Number": "companyRegNumber",
    "First Name": "firstName",
    "Last Name": "lastName",
    Email: "emailAddress",
    "Building Name/Number": "building",
    Street: "street",
    "Town/City": "city",
    County: "county",
    Postcode: "postcode",
    Country: "country",
    "Payment Contact Name": "paymentName",
    "Payment Contact Phone Number": "paymentPhone",
    "Payment Contact Email": "paymentEmail",
    //customs and compliance
    "Billable Customer": "billableCustomer",
    "Standing Authority V2.1": "sa21",
    "Account Application": "accountApplication",
    "Terms & Conditions": "termsAndConditions",
    "Deferment Account": "defermentNumber",
    "Deferment Authorised": "defermentAuthorised",
    "Postponed VAT Accounting": "postponedVatAccounting",
    CCG: "CCGheld",
    "CCG Reference": "CCGReference",
    "Customs Warehouse ID": "warehouseID",
    "Supervising Office": "supervisingOffice",
    'Send data to Customaite?': 'sendToCustomaite',
  };

  //mapping for the tab validation. This will be used to map the validation for the tabs to the correct key in the data
  const tabValidationMapping = {
    Invalid: "Invalid",
    addressValid: "BPaddress",
    billingDetailsValid: "BPBillingDetails",
    cabieValid: "BPCabie",
    companyDetailsValid: "BPcompanydetails",
    customsAndComplianceValid: "BPCustomsAndCompliance",
    documentsValid: "BPDocuments",
  };

  // function to fetch the data for the tabs. This function will retrieve the eori number, company name and the validation for the tabs, which will be used to display or not the red triangle next to the tab name
  const fetchTabInfo = async () => {
    //check if id is 0, if it is, then we're creating a new BP
    if (id === 0 || id === null) {
      setCompanyName("New Business Partner");
      setEoriNumber("");
    } else {
      const returnData = await fetchBusinessPartner("tabs info", id);
      setCompanyName(returnData[0].companyName);
      setEoriNumber(returnData[0].eori);
      formatTabInfo(returnData[0]);
    }
  };

  //function to format the tab info. This function will format the data that we get from the fetchTabInfo function and set the state for isInvalid and tabValidation
  const formatTabInfo = (data) => {
    const returnData = {};
    //loop through the data and get just the key/value pairs where key contains the word 'valid'
    Object.keys(data).forEach((key) => {
      if (key.toLowerCase().includes("valid")) {
        //use the mapping to get the correct key for the tab
        returnData[tabValidationMapping[key]] = data[key];
      }
    });
    //set the state is isInvalid using returnData.Invalid, convertes to boolean. Also, set tabValidation with returnData
    setIsInvalid(!!returnData.Invalid);
    setTabValidation(returnData);
  };

  //function to fetch the data for the business partner
  const handleAddData = async (title, activeTab = "", isTest = false) => {
    let returnData;
    //check if id is 0, if it is, then we have a new BP
    if (id === 0 || id === null) {
      returnData = await fetchBusinessPartner(title, null, isTest);
    }
    //check if title is a key in BPLocalData. If it is, it means we have already fetched the data for this table. If not, we need to fetch the data
    if (localBPData[title]) {
      return;
    } else {
      //fetch the data for the current table
      returnData = await fetchBusinessPartner(title, id, isTest);

      //save the data in the returnData state. returnData will contain the data for all the tables in the current step
      setBPData((prevBPData) => {
        return { ...prevBPData, [title]: returnData };
      });
      setLocalBPData((prevBPData) => {
        return { ...prevBPData, [title]: returnData };
      });
    }
  };

  //function to handle the change of the data. This function will update the localBPData state with the new value, and it will use title / subtitle to find the correct item in the array and update the value in the localBPData state
  const handleOnChange = (subtitle, value, title) => {
    setLocalBPData((prevData) => {
      return {
        ...prevData,
        [title]: [
          ...prevData[title].map((item) => {
            if (item.subtitle === subtitle) {
              return {
                ...item,
                value: value,
              };
            }
            return item;
          }),
        ],
      };
    });
  };

  //function to fetch the dropdown values. This function will fetch the dropdown values for the dropdowns in the business partner page
  const handleFetchBusinessPartnerDropdownValues = async (dropdown) => {
    const response = await fetchBusinessPartnerDropdownValues(dropdown);
    //format the data to be an object with the statusNumber as the key and the data as the value
    const formatedData = response.reduce((acc, item) => {
      acc[item.statusNumber] = item.data;
      return acc;
    }, {});
    //set the dropdownValues state with the new data
    setDropdownValues((prevData) => ({
      ...prevData,
      [dropdown]: formatedData,
    }));
  };

  //function to discard changes. This means that we set localBPData to be BPData and we change isEditing to false
  const handleDiscardChanges = () => {
    setLocalBPData(BPData);
    setIsEditing(!isEditing);
  };

  //function to handle the edit ability. This function will set isEditing to true or false, depending on the current value
  const handleEdit = () => {
    //if isEditing is true, we call handleDiscardChanges, to discard the changes we made and set isEditing to false. If isEditing is false, we just set isEditing to true
    if (isEditing) {
      handleDiscardChanges();
    } else {
      setIsEditing(!isEditing);
    }
  };

  //function to show the modal. This function will set the modalShow state to true
  const handleShowModal = () => {
    setModalShow(true);
  };

  //function to format the data to post. This function will format the data that we have in localBPData to be in the correct format to post to the database
  const formatDataToPost = (data) => {
    const result = {};

    Object.keys(data).forEach((key) => {
      data[key].forEach((item) => {
        const newKey = columnNames[item.subtitle] || item.subtitle; // Use the new key from columnNames if it exists, otherwise use the original subtitle
        result[newKey] = item.value;
      });
    });
    return result;
  };


  //function to save the business partner. This function will save the business partner data to the database
  const handleSave = async (e, activeStep) => {
    e.preventDefault();
    //get the tables for the current step
    const currentTables = stepperTables[activeStep];
    // initialize an empty data object
    const data = {};

    //iterate through each table in the current step and add the data to the data object, being the key the table name and the value the data for that table
    currentTables.forEach((table) => {
      data[table] = localBPData[table];
    });

    //format the data to be in the correct format to post
    const formatedData = formatDataToPost(data, activeStep);

    setIsSaving(true);

    //save the data to the database
    const returnData = await saveBusinessPartner(formatedData, activeStep, id);

    //if id is 0, that means we just created a new BP, so we get the id from the return data and set it to be the current id. 
    if(id === 0 || id === null) {
      setNeeNor(returnData[0].neeNorId)
    }

    //set the newObject variable to be an object with the errors for the current step
    const newObject = Object.keys(columnNames).reduce((acc, key) => {
      const originalKey = columnNames[key] + "Error";
      if (originalKey in returnData[0] && returnData[0][originalKey] !== null) {
        acc[key] = returnData[0][originalKey];
      }
      return acc;
    }, {});

    //set the errors state with the newObject, fetch the tab info (to update the company name and eori number), set bpData to be localBPData (since we saved the new info successfully) and set isSaving and isEditing to false
    setErrors(newObject);
    fetchTabInfo();
    setBPData(localBPData);
    setIsSaving(false);
    setIsEditing(false);
  };

  //function to close the modal. This function will set the modalShow state to false
  const handleCLoseModal = () => {
    setModalShow(false);
  };

  //function to show the warning modal. This function will set the showWarningModal state to true and also take care of the onConfirm callback function
  const handleShowWarningModal = (onConfirm) => {
    //check if bpData and localBPData are the same, and if they are, just execute the onConfirm function
    const isSame = JSON.stringify(BPData) === JSON.stringify(localBPData);
    if(isSame) {
      onConfirm();
    }
    //if they are not the same, set the onConfirmFunction to be the onConfirm function and the handleDiscardChanges function
    else{
      //set a new variable to be the onConfirm function. This variable will be a function that will call the onConfirm function and the handleDiscardChanges function
      const onConfirmFunction = () => {
        onConfirm();
        handleDiscardChanges();
      }

      //if they are not the same, set the onConfirmFunction to be the onConfirm and the handleDiscardChanges function
      setOnConfirmFunction(() => onConfirmFunction);
    }
  }

  //provider values
  const providerValues = {
    isEditing,
    handleEdit,
    handleAddData,
    BPData,
    handleOnChange,
    localBPData,
    isSaving,
    handleSave,
    modalShow,
    handleShowModal,
    handleFetchBusinessPartnerDropdownValues,
    dropdownValues,
    errors,
    back,
    companyName,
    eoriNumber,
    isInvalid,
    setCurrentStep,
    handleShowWarningModal,
    setShowUploadDocumentModal,
    setShowDocumentsListModal
  };


    return (
      <BusinessPartnerContext.Provider value={providerValues}>
        <BPModal
          show={modalShow}
          onHide={handleCLoseModal}
          onChange={handleOnChange}
          customerId={id}
        />
        <BPDocumentListModal companyId={id} show={showDocumentsListModal} onHide={() => setShowDocumentsListModal(false)} />
        <BPWarningModal show={showWarningModal} onHide={() => setOnConfirmFunction(null)} onConfirm={onConfirmFunction}/>
        <BPUploadDocumentModal show={showUploadDocumentModal} onHide={() => setShowUploadDocumentModal(false)} customerId={id} currentStep={currentStep}/>
        <Container className="container-md">
          <section
            className="floating-box"
            style={{ maxWidth: "1000px", backgroundColor: "white", marginLeft: "auto", marginRight: "auto" }}
          >
            <Stepper
              showFuture={true}
              canSkipForward={true}
              stepClassName="business-partner-section"
              tabValidation={tabValidation}
              useModalToChangeTabs={true}
              handleShowWarningModal={handleShowWarningModal}
            >
              <main
                className="business-partner-container no-graphics"
                style={{ position: "relative" }}
              >
                {
                  <Stepper.StepList
                    isSticky={true}
                    title={
                      <div className="epo-steps-stepper-header">
                        Business Partner
                      </div>
                    }
                  />
                }
                <Stepper.Step text="Company Details" stepKey="BPcompanydetails">
                  <BPCompanyDetails />
                </Stepper.Step>
                {id === 0 || id == null ? null : ( //only display this block is id is not null or 0
                  <>
                    <Stepper.Step text="Address" stepKey="BPaddress">
                      <BPAddress />
                    </Stepper.Step>
                    <Stepper.Step
                      text="Customs & Compliance"
                      stepKey="BPCustomsAndCompliance"
                    >
                      <BPCustomsAndCompliance />
                    </Stepper.Step>
                    <Stepper.Step text="CABIE" stepKey="BPCabie">
                      <BPCabie />
                    </Stepper.Step>
                    <Stepper.Step text="Billing Details" stepKey="BPBillingDetails">
                      <BPBillingDetails />
                    </Stepper.Step>
                    <Stepper.Step text="Documents" stepKey="BPDocuments">
                      <BPDocuments companyId={id} isModalShowing={showUploadDocumentModal}/>
                    </Stepper.Step>
                  </>
                )}
              </main>
            </Stepper>
          </section>
        </Container>
      </BusinessPartnerContext.Provider>
    );
};
export default BusinessPartnerCard;
