import { useContext, useEffect, React } from "react";
import BPHeader from "./BPHeader";
import BPFooter from "./BPFooter";
import { useStepper } from "../Stepper";

// Context
import { BusinessPartnerContext } from "./BusinessPartnerCard";
import BPGrid from "./BPTestTable";

const BPCabie = () => {
  const { activeStepKey } = useStepper();
  const {
    localBPData,
    handleAddData,
    isEditing,
    handleOnChange,
    handleFetchBusinessPartnerDropdownValues,
    dropdownValues,
    errors,
    handleShowModal,
    setCurrentStep
  } = useContext(BusinessPartnerContext);

  useEffect(() => {
    if (activeStepKey === "BPCabie") {
      handleAddData("Trader Notes", activeStepKey, false);
      handleAddData("Trader Data", activeStepKey, false);

      handleFetchBusinessPartnerDropdownValues("Send data to Customaite?");
      setCurrentStep("BPCabie");
    }
  }, [activeStepKey]);

  const renderData = (title) => {
    const data = localBPData[title];
    if (!data) {
      return <BPGrid.Row title="Loading..." />;
    } else {
      return data.map((item, index) => {
        //set hasError to true if errors?.[item?.subtitle] exists
        const hasError = errors?.[item?.subtitle] ? true : false;
        return (
          <BPGrid.Row
            title={index === 0 ? title : ""}
            subTitle={item.subtitle}
            value={item.value}
            isEditingValue={isEditing}
            valueType={item.Type}
            dropdownValues={dropdownValues?.[item?.subtitle]}
            onChange={handleOnChange}
            tableTitle={title}
            hasError={hasError}
            errorMessage={errors?.[item?.subtitle]}
            setModalShow={handleShowModal}
          />
        );
      });
    }
  };

  return (
    <>
      <section className="bp-cabie">
        <BPHeader />
        <div className="bp-section-heading">Trader Notes</div>
        <BPGrid numCols={2} isEditing={isEditing}>
          {renderData("Trader Notes")}
        </BPGrid>
        <div className="bp-section-heading">Trader Data</div>
        <BPGrid numCols={2} isEditing={isEditing}>
          {renderData("Trader Data")}
        </BPGrid>
      </section>
      <BPFooter />
    </>
  );
};


export default BPCabie;
