import { logout } from "./Security/securityReducer";

const url = process.env.REACT_APP_API;
const reporturl = process.env.REACT_APP_REPORT;
const urlForLogin = url + 'companyLogin';
const urlForAddUser = url + 'addUser';
const urlForCompanys = url + 'companies';
const urlForinvoiceSearch = url + 'invoices';
const urlForinvoiceDetails = url + 'invoicedetails?invoice=';
const urlForTeamMembers = url + 'teammembers/';
const urlFroValidateEORI = url + 'eori?eori=';
const urlForValidateVAT = url + "vat?eori=";
const urlForNeeNor = url + 'neenor/';
const urlForNeeNorDetails = url + 'neenor/details?id=';
const urlForNewLoad = url + 'newload/';
const urlForMasterData = url + 'masterdata/';
const urlForProductdata = url + 'productdata';
const urlForProducts = url + 'products';
const urlForPruductsDetails = url + 'products/details?id=';
const urlForCompanyProducts = url + 'newload/products';
const urlFroValidateProduct = url + 'products/validate?product=';
const urlForReports = url + 'reports/';
const urlForUserAdmin = url + 'useradmin/'
const urlForUserDetails = url + 'useradmin/details?userid='
const urlForTeamMemberDetails = url + 'teammembers/details?id=';
const urlForListLoads = url + 'listload/'
const urlForFileUpload = url + 'fileUpload/'
const urlForForgottenpassword = url + 'forgottenpassword/'
const urlForEIDR = url + 'eidr?id=';
const urlForSDI = url + 'descartes/sdi';
const urlForSFD = url + 'descartes/sfd';
const urlForC21 = url + 'descartes/c21';
const urlForEAD = url + 'descartes/ead';
const urlForMaintenance = url + 'maintenance/';
const urlForCustomerData = url + 'customerdata'
const urlForAdditionalFileUpload = url + 'additionalFileUpload/add'
const urlForAdditionalFileDelete = url + 'additionalFileUpload/delete'
const urlForAdditionalFileNames = url + 'additionalFileUpload/get'
const urlForAdditionalFileData = url + 'additionalFileUpload/getData'
const urlForDocumentTypes = url + 'documentTypes'
const urlForOrderForm = url + 'orderform';
const urlForCommodityInfo = url + 'commCodeInfo';
const urlForMeasuresInfo = url + 'measuresInfo';
const urlForCountryInfo = url + 'countryInfo';
const urlForEPOData = url + 'EPO/';
const urlForDocusign = url+ 'docusign/generatePage'
const urlForRegistration = url + 'registration/';
const urlForMessages = url + 'messaging/messages'
const urlForComments = url + 'messaging/comments'
const urlForAdditionalEPOFileUpload = url + 'EPO/AddInvoice'
const urlForGATrackingCode = url + "gaTrackingCode"
const urlForLspAccess = url + "lspAccess/"
const urlForWLP = url + "whitelabel/"
const urlForCompanyList = url + 'useradmin/companyList' 
const urlForReportList = reporturl + 'reports/' 
const urlForSelectedReport = reporturl + 'it/' 
const urlForCommcode = url + "commcode/"
const urlForReportDashboard = reporturl + 'dashboard/'
const urlBusinessPartner = url + 'businesspartner/'
const urlForBusinessPartnerAddNote = url + 'businesspartner/addNote'
const urlForBusinessPartnerGetNotes = url + 'businesspartner/getNotes?id='
const urlForBusinessPartnerDeleteNote = url + 'businesspartner/deleteNote?id={id}&customerId={customerId}';


/*
retrieves the list of BP from the DB
*/
export const postBusinessPartnerList = async (submit) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    "Content-Type": "application/json"
  }
  const response = await fetch(urlForNeeNor+"partner", {
    method: 'POST',
    headers: header,
    body: JSON.stringify(submit)
    
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}
/*============================================== */


export const postBusinessPartnerDocument = async (formData) => { 
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(`${urlBusinessPartner}fileUpload`, {
    method: 'POST',
    body: formData,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

//function to get the Business Partner data from the backend
export const putBusinessPartner = async (data, activeStep, businessPartnerId) => {
  console.log('activeStep', activeStep)
  const isTest = false;
  let tabToUpdate = ''
    switch (activeStep) {
        case "BPcompanydetails":
          tabToUpdate = 'companyDetails'   
            break;
        case "BPaddress":
          tabToUpdate = 'address'   
        break;
        case "BPBillingDetails":
          tabToUpdate = 'billingDetails'   
        break;
        case "BPCustomsAndCompliance":
          tabToUpdate = 'customsCompliance'   
        break;
        case "BPCabie":
          tabToUpdate = 'traderData'   
        break;
        default:
            break;
    }
  const endpoint = `${urlBusinessPartner}${tabToUpdate}?id=${businessPartnerId}`
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
    if (isTest) {
      // Simulate server delay
    return new Promise((resolve, reject) => {
      let returnData = {}
      setTimeout(() => {
        returnData = data;
        resolve(returnData);
      }, 2000);
    });
    }
    else {
        const response = await fetch(endpoint, {
          method: 'PUT',
          headers: header,
          body: JSON.stringify(data)
        })
      if (response.status >= 400) {
        throw (new Error('Network request failed'))
      } else {
        return await response.json()
      }
    }
}

export const getBusinessParnerDocumentTypeDropdown = async () => {
  const endpoint = `${urlBusinessPartner}loadtype`
  const token = sessionStorage.getItem('token');

  const header = {
    Authorization: `${token}`
  }

  const response = await fetch(endpoint, {
    method: 'get',
    headers: header,
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getBusinessParnerDocumentDescriptionDropdown = async () => {
  const endpoint = `${urlBusinessPartner}loadDescription`
  const token = sessionStorage.getItem('token');

  const header = {
    Authorization: `${token}`
  }

  const response = await fetch(endpoint, {
    method: 'get',
    headers: header,
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getBusinessParnerDocumentDetails = async (documentId) => {
  const endpoint = `${urlBusinessPartner}documentdetails?id=${documentId}`
  const token = sessionStorage.getItem('token');

  const header = {
    Authorization: `${token}`
  }

  const response = await fetch(endpoint, {
    method: 'get',
    headers: header,
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getBusinessParnerDocumentsList = async (companyId) => {
  const endpoint = `${urlBusinessPartner}documentlist?id=${companyId}`
  const token = sessionStorage.getItem('token');

  const header = {
    Authorization: `${token}`
  }

  const response = await fetch(endpoint, {
    method: 'get',
    headers: header,
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

/*function to get dropdown values for the Business Partner data from the backend
params:
dropdownvalue: string - the name of the dropdown we want to fetch the values for

returns:
response: object - the response from the API call, containing the values for the selected dropdown
==================================================================*/
export const getBusinessParnerDropdownValues = async (dropdownvalue) => {
  let endpoint = '';
  switch (dropdownvalue) {
    case 'CABIE Status':
      endpoint = `${urlBusinessPartner}CABIEStatusDropdown`
      break;
    case 'Secondary Status':
      endpoint = `${urlBusinessPartner}SecondaryStatusDropdown`
      break;
    default:
      throw (new Error('Invalid endpoint for dropdown values'))
  }
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }

  const response = await fetch(endpoint, {
      method: 'get',
      headers: header,
    })
  
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

//function to get the Business Partner data from the backend
export const getBusinessPartner = async (title, businessPartnerId, isTest) => {
  let endpoint = ''
  switch (title) {
    case 'Company':
      endpoint = `${urlBusinessPartner}company?id=${businessPartnerId}`
      break;
    case 'Main Contact Details':
      console.log('inside main contact details', businessPartnerId)
      endpoint = `${urlBusinessPartner}mainContactDetails?id=${businessPartnerId}`
      break;
    case 'Business Partner':
      endpoint = `${urlBusinessPartner}businessPartner?id=${businessPartnerId}`
      break;
    case 'Address':
      endpoint = `${urlBusinessPartner}address?id=${businessPartnerId}`
      break;
    case 'Company Payment Details':
      endpoint = `${urlBusinessPartner}companyPaymentDetails?id=${businessPartnerId}`
    break;
    case 'Authorisation Documents':
      endpoint = `${urlBusinessPartner}authorisationDocuments?id=${businessPartnerId}`
    break;
    case 'Customs Authorisations':
      endpoint = `${urlBusinessPartner}customsAuthorisations?id=${businessPartnerId}`
    break;
    case 'tabs info':
      endpoint = `${urlBusinessPartner}PartnerStatus?id=${businessPartnerId}`
    break;
    case 'Trader Data':
      endpoint = `${urlBusinessPartner}traderData?id=${businessPartnerId}`
    break;
    default:
      endpoint = `${urlBusinessPartner}company?id=${businessPartnerId}`
      break;
  }
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    if (isTest) {
      // Simulate server delay
    return new Promise((resolve, reject) => {
      let returnData = {}
      setTimeout(() => {
        if(title === 'BPaddress'){
          returnData = {
            title: "Address",
            values: [
              { subtitle: "Building Name / Number", value: "Building name ex", type: "string" },
              { subtitle: "Street", value: "North Bank", type: "string" },
              { subtitle: "Town / City", value: "Chesterfield", type: "string" },
              { subtitle: "County", value: "South Yorkshire", type: "string" },
              { subtitle: "Postcode", value: "S38JD", type: "string" },
              { subtitle: "Country", value: "United Kingdom", type: "string" },
            ],
          }
        }
        else if(title === 'Business Partner'){
          returnData = {
            title: "Business Partner",
            values: [
              { subtitle: "Type", value: "Trader", type: "string" },
              {
                subtitle: "Cabie Status",
                value: "APPROVED",
                type: "dropdown",
                additionalValues: ["APPROVED", "ON-HOLD"],
              },
              {
                subtitle: "Secondary Status",
                value: "",
                type: "dropdown",
                additionalValues: [
                  "",
                  "CLOSED ACCOUNT",
                  "TEMP ON STOP (Finance)",
                  "TEMP ON STOP (No SA)",
                  "BLACK LISTED",
                ],
              },
            ],
          }
        }

        else if(title === 'BPAuthorisationDocuments'){
          returnData = {
            title: "Authorisation Documents",
            values: [
              {subtitle: "Billable Customer", value: "Yes", type:"dropdown", additionalValues: ["Yes", "No"] },
              {subtitle: "CABIE Pack", value: ["Standard Authority", "Account Application", "Terms and Conditions"], type:"checkbox", additionalValues: [true, false, true]},

            ]
          }
        }

        else if(title === 'BPTraderNotes'){
          returnData = {
            title: "Trader Notes",
            values: [
              {subtitle: "NotesText", value: 'this is a text', type:"notes"}, 
              // {subtitle: "NotesImage", value: 'imagetext', type:"image"}, 
            ]
          }
        }
        else if(title === 'BPTraderData'){
          returnData = {
            title: "Trader Data",
            values: [
              {subtitle: "Send to Customaite", value: "Yes", type:"dropdown", additionalValues: ["Yes", "No"] },
            ]
          }
        }


        else if(title === 'BPPaymentDetails'){
          returnData = {
            title: "Payment Details",
            values: [
              {subtitle: "Payment Contact Name", value: "", type:"string"},
              {subtitle: "Payment Contact Telephone number", value: "", type:"string"},
              {subtitle: "Payment Contact Email", value: "", type:"string"},
            ]
          }
        }


        else if(title === 'BPCustomsAutorisations'){
          returnData = {
            title: "Customs Authorisations",
            values: [
              {subtitle: "Deferment Account", value: "", type:"string" },
              {subtitle: "Deferment Authorised", value: "Yes", type:"dropdown", additionalValues: ["Yes", "No"] },
              {subtitle: "Postponed VAT Accounting", value: "Yes", type:"dropdown", additionalValues: ["Yes", "No"] },
              {subtitle: "CCG", value: "Yes", type:"dropdown", additionalValues: ["Yes", "No"] },
              {subtitle: "CCG Reference", value: "", type:"string" },
              {subtitle: "Customs Warehouse ID", value: "", type:"string" },
              {subtitle: "Supervising Office", value: "", type:"string" },
            ]
          }
        }

        else if(title === 'Company'){
          returnData = {
            title: "Company",
            values: [
              {subtitle: "Company Name", value: "Portadown Locksmiths", type:"string" },
              {subtitle: "Legal Entity", value: "PORTADOWN LOCKSMITHS LTD", type:"string" },
              {subtitle: "Jurisdiction", value: "Test Jurisdiction", type:"string" },
              {subtitle: "Trader EORI Number", value: "GB800776637000", type:"string"},
              {subtitle: "VAT Number", value: "123456", type:"string" },
              {subtitle: "Company Registration Number", value: "123456", type:"string" },
            ]
          }
        }
        else if(title === 'Main Contact Details'){
          returnData = {
            title: "Main Contact Details",
            values: [
              {subtitle: "First Name", value: "John", type:"string" },
              {subtitle: "Last Name", value: "Doe", type:"string" },
              {subtitle: "Email", value: "johndoe@email.com", type:"string" },
              {subtitle: "Business Phone Number", value: "+44 73 55 555555", type:"string" },
              {subtitle: "Position in Company", value: "CEO", type:"string" },    
            ]
          }
        }
        else{
          // Test JSON
          returnData = {
            key: "something...",
            key2: "something else...",
            // Add more key-value pairs as needed
          };
        }
        resolve(returnData);
      }, 1); // Delay for 2 seconds
    });
    }
    else {
        const response = await fetch(endpoint, {
          method: 'get',
          headers: header,
        })
      
      if (response.status >= 400) {
        throw (new Error('Network request failed'))
      } else {
        return await response.json()
      }
    }
}

//function to get the dashboard data from the report API
export const getDashboardReport = async (data) => {
  const istest = false;
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  if (istest) {
      // Simulate server delay
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Test JSON
      const testJson = {
        key: urlForReportDashboard,
        // Add more key-value pairs as needed
      };

      resolve(testJson);
    }, 2000); // Delay for 2 seconds
  });
  }
  else{
  const response = await fetch(urlForReportDashboard, { //TODO: change this to the correct endpoint
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
  }


}


export const getCommcodeRefreshTable = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCommcode+ "refreshTable", {
    headers: header,
    method: 'GET'
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getUpdateCommcodeSingle = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCommcode+ "single?id="+id, {
    headers: header,
    method: 'GET'
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getUpdateCommcodeAll = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCommcode+ "all", {
    headers: header,
    method: 'GET'
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postSelectedReport = async (data, fd) => {
  console.log('api data ', data)
  console.log('fd fd ', fd)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForSelectedReport + data, {
    method: 'POST',
    headers: header,
    body: fd
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getReportsDropdown = async (id, data) => {

   const token = sessionStorage.getItem('token');
   const header = {
     Authorization: `${token}`
   }
   const response = await fetch(urlForReportList + data + '?id='+ id, {
     headers: header
   })
   if (response.status >= 400) {
     throw (new Error('Network request failed'))
   } else {
     return await response.json()
   }
 }

export const getSingleReport = async (data, id) => {
  let reportName = "";
   if(id){
     reportName = id;
   }
   const token = sessionStorage.getItem('token');
   const header = {
     Authorization: `${token}`
   }
   const response = await fetch(urlForReportList + `${data}?reportName=${reportName}`, {
     headers: header
   })
   if (response.status >= 400) {
     throw (new Error('Network request failed'))
   } else {
     return await response.json()
   }
 }

export const getReportList = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForReportList+'reportList', {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postEPOMovementDirection = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + "epoMovementDirection", {
    method: 'POST',
    body: data,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postEPOExport = async (endPoint, data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + endPoint, {
    method: 'POST',
    body: data,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getEPOPortDropdown = async (data, id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + "port" + "?import=" + data + '&directionid=' + id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getEpoForm = async (endPoint, id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + endPoint +'?id='+ id, {
    method:  'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getEpoDropdown = async (id, data) => {

  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + data + '?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getCompanyList = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCompanyList, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const updateUserStatus = async (endpoint, userIds) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: token,
    'Content-Type': 'application/json'
  };
  const response = await fetch(`${urlForUserAdmin}${endpoint}`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify(userIds)
  });

  if (response.status >= 400) {
    throw new Error('Network request failed');
  }
  return await response.json();
};

export const updateCompanyName = async (id, name) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: token,
    'Content-Type': 'application/json'
  };
  const response = await fetch(`${urlForUserAdmin}updateCompanyName`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify({id, name})
  });

  if (response.status >= 400) {
    throw new Error('Network request failed');
  }
  return await response.json();
};

export const userAdminDeleteEPO = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: token,
    'Content-Type': 'application/json'
  };
  const response = await fetch(`${urlForUserAdmin}deleteEPO`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify({id})
  });

  if (response.status >= 400) {
    throw new Error('Network request failed');
  }
  return await response.json();
};

export const userAdminRestoreEPO = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: token,
    'Content-Type': 'application/json'
  };
  const response = await fetch(`${urlForUserAdmin}restoreEPO`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify({id})
  });

  if (response.status >= 400) {
    throw new Error('Network request failed');
  }
  return await response.json();
};

export const updateDepartmentAndRole = async (userIds, department, role) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: token,
    'Content-Type': 'application/json'
  };
  const response = await fetch(`${urlForUserAdmin}updateDepartmentAndRole`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify({userIds, department, role})
  });

  if (response.status >= 400) {
    throw new Error('Network request failed');
  }
  return await response.json();
};

export const updateRole = async (userIds, role) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: token,
    'Content-Type': 'application/json'
  };
  const response = await fetch(`${urlForUserAdmin}updateRole`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify({userIds, role})
  });

  if (response.status >= 400) {
    throw new Error('Network request failed');
  }
  return await response.json();
};


export const postAdminPasswordreset = async (formData) => {
  const response = await fetch(`${urlForUserAdmin}${'sendemail'}`, {
    method: 'POST',
    body: formData
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getDDAStatus = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + "DDA?id=" + id, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postCertsList = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + "certs", {
    method: 'POST',
    body: data,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getLspTradersIDs = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + "lspTraders", {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getChed = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + "ched?id=" + id, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const deleteChed = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData + "ched?id=" + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postNewWLP = async (submitData) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin+ "newwlp", {
    method: 'POST',
    body: submitData,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getWLPTraders = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForWLP + "traders", {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}
export const getWLPLsp = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForWLP + "lsps", {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getWLPLoads = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForWLP + "loads", {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getWLPRelationships = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForWLP + "relationships?id=" + id, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNominatedUsers = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForLspAccess, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNominatedUserDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForLspAccess + "details?id=" + id, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postNominatedUserSearch = async (data) => {
  return [
    {id: 1, firstName: 'Joe', lastName: 'Bloggs', email: 'joe.bloggs@aol.com', eoriNumber: 'GB123'},
    {id: 2, firstName: 'Ben', lastName: 'Smith', email: 'ben.bloggs@aol.com', eoriNumber: 'ABC011'},
  ]
}

export const postNominatedUser = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForLspAccess, {
    method: 'POST',
    body: data,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}


export const getGATrackingCode = async () => {
  const response = await fetch(urlForGATrackingCode, {
    method: 'GET',
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}


export const postNBPNotes = async (formData) => { 
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForBusinessPartnerAddNote, {
    method: 'POST',
    body: formData,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const deleteNBPNote = async (noteId, customerId) => {

  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch( urlForBusinessPartnerDeleteNote.replace('{id}', noteId).replace('{customerId}', customerId),{
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return "success";
  }
}

  export const getNBPNotes = async (id) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForBusinessPartnerGetNotes + id,{
      method: 'GET',
      headers: header
    })
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }


export const postAdditionalEPOFile = async (formData) => { 
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForAdditionalEPOFileUpload, {
    method: 'POST',
    body: formData,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getMessages = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMessages, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}


export const getComments = async (msgId) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForComments+"?msgId="+msgId, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postMessage = async (formData) => { 
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMessages, {
    method: 'POST',
    body: formData,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postComment = async (formData) => { 
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForComments, {
    method: 'POST',
    body: formData,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return "success"
}


async function fetch(...args) {
  return window.fetch(...args).then(async resp => {
    // If token has expired; force re-auth.
    if (resp.status === 401) {
      logout();
      const q = new URLSearchParams();
      q.append('authError', "Session expired, please log in again.")
      window.location.assign('/login?' + q.toString());
      return;
    }
    // If user is forbidden; redirect away from page.
    if (resp.status === 403) {
      const q = new URLSearchParams();
      q.append('authError', "Sorry, you don't have permission to do that.")
      window.location.assign('/main?'+q.toString());
      return;
    }

    if (resp.status === 400 && resp.url === url + 'forgottenpassword/resetpassword') {

      return Promise.resolve(resp);
    }
    if (resp.ok) {
      return resp;
    } else {
      const error = await resp.text();
      return Promise.reject(new Error(error));
    }
  })
}

export const postDocusignPage = async (formData) => {
  const response = await fetch(urlForDocusign, {
    method: 'POST',
    body: formData
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const putDocusignSigned = async (state, existingCustomer, event) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }

  const fd = new FormData();
  fd.append('state', state);
  fd.append('existingCustomer', (existingCustomer === true || existingCustomer === "true"))
  fd.append('event', event);
  const response = await fetch(urlForRegistration + 'signed', {
    method: 'PUT',
    headers: header,
    body: fd
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return "success";
}

export const postDocusignReopen = async(envelopeId) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }

  const fd = new FormData();
  fd.append('envelopeId', envelopeId);
  const response = await fetch(urlForRegistration + 'reopenEnvelope', {
    method: 'POST',
    headers: header,
    body: fd
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return response.json()
}

export const postDocusignResend = async(id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }

  const fd = new FormData();
  fd.append('state', id);
  const response = await fetch(urlForRegistration + 'resendEnvelope', {
    method: 'POST',
    headers: header,
    body: fd
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return response.json()
}

export const getDocumentTypes = async () => {
  const response = await fetch(urlForDocumentTypes)
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}
export const getAdditionalFileData = async (documentID) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForAdditionalFileData + '?documentID='+documentID, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.blob();
}

export const getAdditionalFileNames = async (newLoadID) => {
  console.log("Called getAdditionalFileNames")
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForAdditionalFileNames + '?newLoadID='+newLoadID, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postAdditionalFileUpload = async (formdata) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForAdditionalFileUpload, {
    method: 'POST',
    headers: header,
    body: formdata
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return "success"
}

export const deleteAdditionalFileUpload = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForAdditionalFileDelete + '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return "success";
  }
}

export const getPortProcessDropdown = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + data , {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getCutomerData = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCustomerData , {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const putCutomerData = async (submit) => { 
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCustomerData, {
    method: 'PUT',
    body: dataform,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return true;
}

export const postCutomerData = async (submit) => { 
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCustomerData, {
    method: 'POST',
    body: dataform,
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getCutomerDataDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCustomerData + '/details?id=' + id, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const deleteCutomerData = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCustomerData + '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getEidr = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEIDR + data, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postSDISubmit = async (submit) => {
  const token = sessionStorage.getItem('token');
  const body = JSON.stringify(submit)
  const header = {
    Authorization: `${token}`
    , 'Content-Type': 'application/json'
  }
  const response = await fetch(urlForSDI, {
    method: 'POST',
    headers: header,
    body: body
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}


export const postSFDSubmit = async (submit) => {
  const token = sessionStorage.getItem('token');
  const body = JSON.stringify(submit)
  const header = {
    Authorization: `${token}`
    , 'Content-Type': 'application/json'
  }
  const response = await fetch(urlForSFD, {
    method: 'POST',
    headers: header,
    body: body
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postC21Submit = async (submit) => {
  const token = sessionStorage.getItem('token');
  const body = JSON.stringify(submit)
  const header = {
    Authorization: `${token}`
    , 'Content-Type': 'application/json'
  }
  const response = await fetch(urlForC21, {
    method: 'POST',
    headers: header,
    body: body
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postEADSubmit = async (submit) => {
  const token = sessionStorage.getItem('token');
  const body = JSON.stringify(submit)
  const header = {
    Authorization: `${token}`
    , 'Content-Type': 'application/json'
  }
  const response = await fetch(urlForEAD, {
    method: 'POST',
    headers: header,
    body: body
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}


export const postFileUpload = async (url, file) => {
  const dataform = file
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForFileUpload+url, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postPasswordreset = async (submit) => {
  console.log("postPasswordreset called",submit);
  const dataform = new FormData(submit)
  const response = await fetch(urlForForgottenpassword+'sendemail', {
    method: 'POST',
    body: dataform
  })
  if (response.status >= 400) {
    sessionStorage.setItem('loggedIn', 'failed');
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postActivated = async (submit) => {
  const dataform = new FormData(submit)
  const response = await fetch(urlForMasterData+'activated', {
    method: 'POST',
    body: dataform
  })
  if (response.status >= 400) {
    sessionStorage.setItem('loggedIn', 'failed');
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postActivatedAndReset = async (submit) => { 
  const dataform = new FormData(submit)
  const response = await fetch(urlForMasterData+'activateAndReset', {
    method: 'POST',
    body: dataform
  })
  if (response.status >= 400) {
    sessionStorage.setItem('loggedIn', 'failed');
    throw (new Error('Network request failed'))
  };
  return await response.json()
}


export const putPasswordreset = async (submit) => { 
  const dataform = new FormData(submit)
  const response = await fetch(urlForForgottenpassword+'resetpassword', {
    method: 'PUT',
    body: dataform
  })
  if (response.status >= 400) {
    sessionStorage.setItem('loggedIn', 'failed');
    return await response.json()
  };
  return await response.json()
}

export const companylogin = async (submit) => {
  const dataform = new FormData(submit)
  const response = await fetch(urlForLogin, {
    method: 'POST',
    body: dataform
  })
  if (response.status >= 400) {
    sessionStorage.setItem('loggedIn', 'failed');
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const addUser = async (submit) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForAddUser, {
    method: 'POST',
    headers: header,
    body: submit
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getCompanys = async (type) => {
  const response = await fetch(urlForCompanys)
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}
export const invoiceSearch = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForinvoiceSearch, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getInvoiceDetails = async (invoice) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForinvoiceDetails + invoice, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getTeamMemberDetails = async (invoice) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForTeamMemberDetails + invoice, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const deleteTeamMemberDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForTeamMembers + '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postTeamMemberDetails = async (submit, data) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin + data, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getTeamMembers = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForTeamMembers, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}
export const postTeamMembers = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForTeamMembers, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const putTeamMembers = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForTeamMembers, {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const checkEORIvalid = async (eori) => {
  const response = await fetch(urlFroValidateEORI + eori.toUpperCase())
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const checkVatValid = async (vat) => {
  const response = await fetch(urlForValidateVAT + vat)
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postproductData = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForProductdata, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const masterData = async (submit) => {
  const dataform = new FormData(submit)
  const response = await fetch(urlForMasterData, {
    method: 'POST',
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getMasterData = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMasterData, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const checkProductvalid = async (product) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlFroValidateProduct + product, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getProductsForCompany = async (companyId) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForCompanyProducts + '?companyId=' + companyId, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getImportExport = async (id, type) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + 'importexport?id=' + id + '&type=' + type, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getProducts = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForProducts, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postProducts = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForProducts, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getNeeNor = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNeeNor, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}
export const postNeeNor = async (submit) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    "Content-Type": "application/json"
  }
  const response = await fetch(urlForNeeNor, {
    method: 'POST',
    headers: header,
    body: JSON.stringify(submit)
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getReports = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForReports + data, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getReportDetails = async (data, id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForReports + data + '?directionid=' + id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getListLoad = async (data, id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForListLoads + data + '?id=' + id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNewLoad = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + data, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNewLoadNeeNor = async (id, type) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + 'neenor?id=' + id + '&type=' + type, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getCompanyNameFromId = async (companyId) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + 'companyNameFromId?companyId=' + companyId, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getUsers = async (data, id) => {
 let companyId = "";

  if(id){
    companyId = id;
  }
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin + `${data}?companyId=${companyId}`, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}


export const getUsersSearch = async (body) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    "Content-Type": "application/json"
  }
  const response = await fetch(urlForUserAdmin +"advancedSearch", {
    headers: header,
    method: 'POST',
    body: JSON.stringify(body ?? {})
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNewLoadProcesses = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + data, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getUserDetails = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserDetails + data, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postUserDetails = async (fd) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin + "add", {
    method: 'POST',
    headers: header,
    body: fd
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}


export const postUsers = async (submit, data) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin + data, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getNewLoadDetails = async (data, id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + data + '?directionid=' + id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNewLoadDropdown = async (data, id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + data + '?directionid=' + id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNewLoadNeeNorDropdown = async (data, id, type, search, skip, rpaID) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + data + '?id=' + id + '&type=' + type + 
  (search ? '&search='+search : '') + 
  (skip ? '&skip=' + skip : '') + 
  (rpaID ? '&rpaID=' + rpaID : ''), {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getNewLoadNeeDropdown = async (data, id, type, search, skip, rpaID) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData+"neenor"+ '?id=' + id + '&type=' + type + 
  (search ? '&search='+search : '') + 
  (skip ? '&skip=' + skip : '') + 
  (rpaID ? '&rpaID=' + rpaID : ''), {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const getDestCountryOptions  = async (isImport, newLoadId) => {
  console.log("newLoadId",newLoadId);
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForEPOData+"despDest"+'?import='+isImport+'&id=' + newLoadId , {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postListLoad = async (data, url) => {
  const token = sessionStorage.getItem('token');
  const body = JSON.stringify(data)
  const header = {
    Authorization: `${token}`
    , 'Content-Type': 'application/json'
  }
  const response = await fetch(urlForListLoads + url, {
    method: 'POST',
    headers: header,
    body: body
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const postNewLoad = async (submit, url) => {
  const token = sessionStorage.getItem('token');
  const body = JSON.stringify(submit)
  const header = {
    Authorization: `${token}`
    , 'Content-Type': 'application/json'
  }
  console.log('url is', urlForNewLoad + url)
  const response = await fetch(urlForNewLoad + url, {
    method: 'POST',
    headers: header,
    body: body
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    if(url == 'validation'){
      const responseJson = await response.json()
      // responseJson[0]['catchCertError'] = 'This is a test error for catch Cert'; //TODO: remove this when the backend is ready
      return responseJson
    }
    return await response.json()
  }
}

export const postNewLoadSearch = async (dataform, url) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNewLoad + url, {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postNewloadLazyLoad = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    'Content-Type': 'application/json'
  }
  const response = await fetch(urlForNewLoad + 'lazyLoad', {
    method: 'POST',
    headers: header,
    body: JSON.stringify(data)
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getNeeNorDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNeeNorDetails + id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const deleteNeeNorDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForNeeNor + '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const putNeeNor = async (submit) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    "Content-Type": "application/json"
  }
  const response = await fetch(urlForNeeNor, {
    method: 'PUT',
    headers: header,
    body: JSON.stringify(submit)
    
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postNeeNorSearch = async (submit) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    "Content-Type": "application/json"
  }
  const response = await fetch(urlForNeeNor+"search", {
    method: 'POST',
    headers: header,
    body: JSON.stringify(submit)
    
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postNeeNorLazyLoad = async (submit) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    "Content-Type": "application/json"
  }
  const response = await fetch(urlForNeeNor+"lazy", {
    method: 'POST',
    headers: header,
    body: JSON.stringify(submit)
    
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getProductDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForPruductsDetails + id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const deleteProductDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForProducts + '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const putProduct = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForProducts, {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const postProductsSearch = async (submit) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    "Content-Type": "application/json"
  }
  const response = await fetch(urlForProducts+"/search", {
    method: 'POST',
    headers: header,
    body: JSON.stringify(submit)
    
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const deletUserDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin + '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const restoreUserDetails = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin + 'restore/?id=' + id, {
    method: 'GET',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

export const putUserDetails = async (fd) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForUserAdmin, {
    method: 'PUT',
    headers: header,
    body: fd
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}


export const putMasterData = async (fd) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMasterData, {
    method: 'PUT',
    headers: header,
    body: fd
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting master data.'))
  };
  return await response.json()
}

export const deleteCommCode = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'commcode'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting commodity code.'))
  } else {
    return await response.json()
  }
}

export const putCommCode = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'commcode', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting commodity code.'))
  };
  return await response.json()
}

export const getCommCode = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'commcode', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving commodity code.'))
  };
  return await response.json()
}

export const postCommCode = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'commcode', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting commodity code.'))
  };
  return await response.json()
}

export const getCommCodeDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'commcodeDetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving commodity code.'))
  };
  return await response.json()
}

export const deleteMovementType = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'movement'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting movement type.'))
  } else {
    return await response.json()
  }
}

export const putMovementType = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'movement', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting movement type.'))
  };
  return await response.json()
}

export const getMovementType = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'movement', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving movement type.'))
  };
  return await response.json()
}

export const postMovementType = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'movement', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting movement type.'))
  };
  return await response.json()
}

export const getMovementTypeDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'movementDetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving movement type.'))
  };
  return await response.json()
}

export const deleteCompanyLoad = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyload'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting company load.'))
  } else {
    return await response.json()
  }
}

export const putCompanyLoad = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyload', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company load.'))
  };
  return await response.json()
}

export const getCompanyLoad = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyload', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company load.'))
  };
  return await response.json()
}

export const postCompanyLoad = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyload', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company load.'))
  } else {

    return await response.json()
  }
}

export const getCompanyLoadDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyloaddetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company load.'))
  };
  return await response.json()
}

export const deleteCompanyCountry = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycountry'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting company country.'))
  } else {
    return await response.json()
  }
}

export const putCompanyCountry = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycountry', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company country.'))
  };
  return await response.json()
}

export const getCompanyCountry = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycountry', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company country.'))
  };
  return await response.json()
}

export const postCompanyCountry = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycountry', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company country.'))
  };
  return await response.json()
}

export const getCompanyCountryDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycountrydetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company country.'))
  };
  return await response.json()
}

export const deleteCompanyPorts = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyports'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting company ports.'))
  } else {
    return await response.json()
  }
}

export const putCompanyPorts = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyports', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company ports.'))
  };
  return await response.json()
}

export const getCompanyPorts = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyports', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company ports.'))
  };
  return await response.json()
}

export const postCompanyPorts = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyports', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company ports.'))
  };
  return await response.json()
}

export const getCompanyPortsDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyportsdetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company ports.'))
  };
  return await response.json()
}

export const deleteShipStatus = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'shipstatus'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting ship status.'))
  } else {
    return await response.json()
  }
}

export const putShipStatus = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'shipstatus', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting ship status.'))
  };
  return await response.json()
}

export const getShipStatus = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'shipstatus', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving ship status.'))
  };
  return await response.json()
}

export const postShipStatus = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'shipstatus', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting ship status.'))
  };
  return await response.json()
}

export const getShipStatusDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'shipstatusdetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving ship status.'))
  };
  return await response.json()
}

export const deleteCompanyCurrency = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycurrency'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting company currency.'))
  } else {
    return await response.json()
  }
}

export const putCompanyCurrency = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycurrency', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company currency.'))
  };
  return await response.json()
}

export const getCompanyCurrency = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycurrency', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company currency.'))
  };
  return await response.json()
}

export const postCompanyCurrency = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycurrency', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company currency.'))
  };
  return await response.json()
}

export const getCompanyCurrencyDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companycurrencydetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company currency.'))
  };
  return await response.json()
}

export const deleteCompanyIncoterm = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyincoterm'+ '?id=' + id, {
    method: 'DELETE',
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error deleting company incoterm.'))
  } else {
    return await response.json()
  }
}

export const putCompanyIncoterm = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyincoterm', {
    method: 'PUT',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company incoterm.'))
  };
  return await response.json()
}

export const getCompanyIncoterm = async () => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyincoterm', {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company incoterm.'))
  };
  return await response.json()
}

export const postCompanyIncoterm = async (submit) => {
  const dataform = new FormData(submit)
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyincoterm', {
    method: 'POST',
    headers: header,
    body: dataform
  })
  if (response.status >= 400) {
    throw (new Error('Error submitting company incoterm.'))
  };
  return await response.json()
}

export const getCompanyIncotermDetail = async (id) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`
  }
  const response = await fetch(urlForMaintenance+'companyincotermdetail'+'?id='+ id, {
    headers: header
  })
  if (response.status >= 400) {
    throw (new Error('Error retrieving company incoterm.'))
  };
  return await response.json()
}

export const postOrderForm = async (submit) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForOrderForm, {
      method: 'POST',
      headers: header,
      body: submit
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const putEPOStatus = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForEPOData + 'status', {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(data)
      })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return true;
    }
  }

  export const updateEPOStatus = async (id) => {
    const fd = new FormData();
    fd.append("id", id);
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForEPOData + 'UpdateEPOStatus', {
      method: 'POST',
      headers: header,
      body: fd
      })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const postEPOHeaders = async (fd) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForEPOData + 'Headers', {
      method: 'POST',
      headers: header,
      body: fd
      // body: body
      })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const putArrivalDate = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForEPOData + 'Arrival', {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(data)
      })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return true;
    }
  }
  
  export const postCommodityInfo = async (fd) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForCommodityInfo, {
      method: 'POST',
      headers: header,
      body: fd
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const getEPO = async (id, url) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForEPOData+ url + '?id='+ id, {
      headers: header,
      method: 'GET'
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const searchEpoList = async (body) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForEPOData +"search", {
      headers: header,
      method: 'POST',
      body: JSON.stringify(body ?? {})
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const searchEpoListLazy = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
    const response = await fetch(urlForEPOData + 'searchLazy', {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    })
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    };
    return await response.json()
  }

  export const getEPOStatuses = async () => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForEPOData + 'Status', {
      headers: header,
      method: 'GET'
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const getCommodityDetail = async (id) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForCommodityInfo+'?id='+ id, {
      headers: header,
      method: 'GET'
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  // get all headers for company = getEPO(company Id, "Headers")
  // get header and initial details associated on header screen = getEPO(Company new load id, "Header")
  // get all invoice details = getEPO(Company New load id, "Invoices")
  // get invoice details and totals = getEPO(Invoice Id, "Invoice")
  // get item details for one invoice = getEPO(Invoice Id, "Item")
  // get all items for header and totals = getEPO(Company new load id, "Items")


  export const putEPO = async (data, url, id) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
    console.log('this is the data', JSON.stringify(data))
    const response = await fetch(urlForEPOData + url + '?id=' + id,  {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(data)
    })
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    };
    let responseJson = await response.json()
    console.log('this is the response from the database', responseJson)
    //responseJson = responseJson.map(obj => ({...obj, RGRWarn: 'testvalue', RGRValid: false, areaMatchValid: false, preferentialWarn: 'test on preferential'}));
    console.log('this is the edited response', responseJson)
    return await responseJson
  }
  // update invoice status = putEPO((Status or filename?), "Invoice", invoiceid )
  // update items for invoice = putEPO((items), "Items", invoiceid )


  export const deleteEPO = async (id, url) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
    const response = await fetch(urlForEPOData + url + '?id=' + id, {
      method: 'DELETE',
      headers: header
    })
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return "success";
    }
  }
  // delete EPO invoice = deleteEPO(invoice id, "Invoice")

  export const getMeasureDetail = async (id) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForMeasuresInfo+'?id='+ id, {
      headers: header,
      method: 'GET'
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const getCountryDetail = async () => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForCountryInfo, {
      headers: header,
      method: 'GET'
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }
  
  export const getEPOList = async (id) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForEPOData + 'List'+'?id='+ id, {
      headers: header
    })
    if (response.status >= 400) {
      throw (new Error('Error retrieving EPO list.'))
    };
    return await response.json()
  }

export const getRegistrationMonthlyDecs = async () => {
  const response = await fetch(urlForRegistration + 'monthlyDecs', {
    method: 'GET'
  })

  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  } else {
    return await response.json()
  }
}

  export const getRegistrationStatuses = async () => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForRegistration + 'Status', {
      headers: header,
      method: 'GET'
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const checkEORIDuplicate = async (eori, email) => {
    const response = await fetch(urlForRegistration + 'duplicate?eori=' + eori.toUpperCase() + '&email=' + email)
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
  }

  export const postInternalRegistrationData = async (body) => {
    const token = sessionStorage.getItem('token');
      const header = {
        Authorization: `${token}`
      }
      const response = await fetch(urlForRegistration+'internal', {
        method: 'POST',
        headers: header,
        body
      })
    
      if (response.status >= 400) {
        throw (new Error('Network request failed'))
      } 
      else {
        return await response.json()
      }
  }
  export const checkNewloadDuplicate = async (data) => {
    const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    }
    const response = await fetch(urlForNewLoad + 'duplicatecheck', {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    })
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    };
    return await response.json()
  }

export const postRegistrationData = async (body) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForRegistration, {
      method: 'POST',
      headers: header,
      body
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } 
    else {
      return await response.json()
    }
  }

export const getRegistrationList = async () => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForRegistration+'list', {
      method: 'get',
      headers: header,
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const postRegistrationListSearch = async (body) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForRegistration+'list', {
      method: 'POST',
      headers: header,
      body: JSON.stringify(body ?? {})
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const postRegistrationListLazy = async (data) => {
  const token = sessionStorage.getItem('token');
  const header = {
    Authorization: `${token}`,
    'Content-Type': 'application/json'
  }
  const response = await fetch(urlForRegistration + 'listLazy', {
    method: 'POST',
    headers: header,
    body: JSON.stringify(data)
  })
  if (response.status >= 400) {
    throw (new Error('Network request failed'))
  };
  return await response.json()
}

export const getRegistrationDetails = async (id) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForRegistration+'details?id='+ id, {
      method: 'get',
      headers: header,
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const getEnvelopeStatus = async (id) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`
    }
    const response = await fetch(urlForRegistration+'envelopeStatus?id='+ id, {
      method: 'get',
      headers: header,
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json();
    }
}

export const getRegistrationType = async() => {
    const response = await fetch(urlForRegistration +'registrationType')
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const putRegistrationDetails = async (data) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForRegistration+'details', {
      method: 'PUT',
      headers: header,
      body: JSON.stringify({data})
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const putRegistrationCompliance = async (data) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForRegistration+'compliance', {
      method: 'PUT',
      headers: header,
      body: JSON.stringify({data})
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const putRegistrationAddress = async (data) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForRegistration+'address', {
      method: 'PUT',
      headers: header,
      body: JSON.stringify({data})
    })
  
    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const putRegistrationEmail = async (data) => {
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForRegistration+'email', {
      method: 'PUT',
      headers: header,
      body: JSON.stringify({data})
    })

    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
}

export const postSendActivationEmail = async (body) => {
  const data = {id:body.id , emailAddress:body.emailAddress}
  const token = sessionStorage.getItem('token');
    const header = {
      Authorization: `${token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(urlForRegistration+'sendActivationEmail', {
      method: 'POST',
      headers: header,
      body: JSON.stringify({data})
    })

    if (response.status >= 400) {
      throw (new Error('Network request failed'))
    } else {
      return await response.json()
    }
} 
