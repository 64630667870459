
import React, { useState, useEffect, useContext, useMemo } from 'react';

import DataTable from "../../../DataTable";
import { EpoJobDetailContext } from "../EpoJobDetails";
import { StoreContext } from '../../../Store';
import { getNewLoad, getEPO } from '../../../apiCalls';
import CopyPastePopUp from "./../../../PopOverCopyPaste/CopyPastePopUp.js";



export default function EpoJobCombinedInvoices({ localInvoiceData }) {
  const { jobDetails, setOpenedInvoice, setTab, tab, sumInvoice, setSumInvoice } = useContext(EpoJobDetailContext);
  const store = useContext(StoreContext);
  const [summaryDetails, setSummaryDetails] = useState([

]);

  useEffect(() => {
    async function fetchData() {
      getNewLoad("vat").then((data) => { store.VatOptions[1](data) });
    }
    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (tab === 'items') {
        const getEPOTabSum = await getEPO(jobDetails.newLoadId, 'summary' + 'tab')
        if (getEPOTabSum) {
          getEPOTabSum.map((row) => {
            row.VAT = store.VatOptions[0].find(opt => parseInt(opt.id) === parseInt(row.VAT))?.data
            return row;
          })
          setSummaryDetails( getEPOTabSum );
        }
      }
      else return
    }
    fetchData();
  }, [tab, sumInvoice])

  const viewInvoice = async (id) => {

    const getEPOInv = await getEPO(id.invoiceId, 'singleinvoicetab')
    if (getEPOInv) {
      setSumInvoice(1)
      setOpenedInvoice(getEPOInv[0]);
    }   
  };

  return (
    <section className="p-2 d-flex flex-column">
      <DataTable data={summaryDetails} className="table-bordered table-striped" showLowerCaseHeaders={true}>
        <DataTable.Headers className="bg-white" style={{ fontSize: "14.5px" }} />
        <DataTable.Column colKey="commcode" header="HS Code" />
        <DataTable.Column colKey="origin" header="Origin" />
        <DataTable.Column colKey="commDesc" header="Description" maxWidth="13ch" />
        <DataTable.Column colKey="packageCount" header="No. Items" />
        <DataTable.Column colKey="packageType" header="Type" />
        <DataTable.Column colKey="grossMass" header="Gross Mass" />
        <DataTable.Column colKey="netMass" header="Net Mass" />
        <DataTable.Column colKey="loadValue" header="Value" maxWidth="13ch" />
        <DataTable.Column colKey="quantity2" header="Qty 2" />
        <DataTable.Column colKey="healthCert" header="Health Cert" maxWidth="13ch" className="p-2 edit-cell" >
              {({healthCert}) => {
                return (
                  <div className="d-flex align-items-center justify-content-between">
                    {healthCert?.length > 7 ? `${healthCert?.slice(0, 7)}` : healthCert}
                    {(healthCert?.length > 7) ? <CopyPastePopUp jobRef = {healthCert}/> : null}
                  </div>
                );
              }} 
        </DataTable.Column>       


        <DataTable.Column colKey="catchCert" header="Catch Cert" maxWidth="13ch" className="p-2 edit-cell">
        {({catchCert}) => {
                return (
                  <div className="d-flex align-items-center justify-content-between">
                    {catchCert?.length > 7 ? `${catchCert?.slice(0, 7)}` : catchCert}
                    {(catchCert?.length > 7) ? <CopyPastePopUp jobRef = {catchCert}/> : null}
                  </div>
                );
              }} 
        </DataTable.Column>   
        <DataTable.Column colKey="meursing" header="meursing" />
        <DataTable.Column colKey="organic" header="Organic" />
        <DataTable.Column colKey="VAT" header="VAT" />
        <DataTable.Column colKey="rgr" header="RGR" />
        <DataTable.Column colKey="preferential" header="Pref" />

        <DataTable.Column colKey="invFileName" header="Filename" maxWidth="13ch" className="p-0" style={{ height: "1px" }}>
          {item => (
            <div className="bold text-truncate invoice-file-button" onClick={() => { viewInvoice(item); }}>
              {item.invFileName.substring(0, 5) + (item.invFileName.length > 5 ? '...' : '')}
            </div>
          )}
        </DataTable.Column>
        <DataTable.Column colKey="invoiceNumber" header="Inv No" maxWidth="17ch" />
      </DataTable>
    </section>
  );
}