import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";

/*
  this component will be used to render warning icon and message
  parameters:
    errorMessage: string - error message to be displayed
*/
const Warning = ({ errorMessage }) => (
  <OverlayTrigger
    placement="top"
    trigger={["hover", "focus"]}
    overlay={
      <Popover style={{ minWidth: "auto" }}>
        <PopoverContent>
          <p>{errorMessage}</p>
        </PopoverContent>
      </Popover>
    }
  >
    <p className="bp-warning-paragraph">
      <span className="warning bp-warning"></span>
    </p>
  </OverlayTrigger>
);

/*
  this component will be used to render dropdown input fields
  parameters:
    value: string - value of the dropdown
    onChange: function - function to be called when dropdown value changes
    options: object - options to be displayed in the dropdown
    isEditing: boolean - flag to check if the dropdown is in editing mode
*/
const DropdownInput = ({ value, onChange, options, isEditing }) => {
  return (
    <select
      className="select-element"
      value={value === null ? "" : value}
      onChange={(e) => onChange(e.target.value)}
      disabled={!isEditing}
    >
      <option value="">-- Select --</option>
      {Object.entries(options).map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  );
};

// this component will be used to render text input fields
const TextInput = ({ value, onChange }) => (
  <input
    type="text"
    defaultValue={value}
    className="input-element"
    onChange={(e) => onChange(e.target.value)}
  />
);

const NotesButton = ({ onClick, linkText }) => <button className="button-text-link view-notes-button" onClick={onClick}>{linkText}</button>;

const CheckboxGroup = ({ value, options, isEditing, subTitle, onChange }) => {
  return (
    <div className="bp-grid-checkboxes fancy-checkbox">
      <span class="checkbox">
        <input
          type="checkbox"
          id={subTitle}
          name={subTitle}
          disabled={!isEditing}
          onChange={(e) => onChange(e.target.checked ? "1" : "0")}
          checked={value !== null && value !== "0"}
        />
        <svg>
          <use xlinkHref="#checkbox" class="checkbox"></use>
        </svg>
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="checkbox" viewBox="0 0 22 22">
          <path fill="none" stroke="currentColor" d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"/>
        </symbol>
      </svg>
    </div>
  );
};

// Element Renderer
const RenderElement = ({
  subTitle,
  value,
  isEditing,
  itemType,
  options,
  onChange,
  hasError,
  errorMessage,
  setModalShow,
}) => {
  if (subTitle === "Type") {
    return (
      <div
        className={`${
          hasError && "bp-grid-error-cell"
        }`}
      >
        <p className="bpgrid-value">{value}</p>
        {hasError && <Warning errorMessage={errorMessage} />}
      </div>
    );
  }

  if (itemType === "dropdown") {
    return (
      <div
        className={`${
          hasError && "bp-grid-error-cell"
        }`}
      >
        <p className="bp-select">
          <DropdownInput
            value={value}
            onChange={onChange}
            options={options}
            isEditing={isEditing}
          />
        </p>
        {hasError && <Warning errorMessage={errorMessage} />}
      </div>
    );
  }

  if (itemType === "notes") {
    return <NotesButton onClick={setModalShow} linkText={"View Notes"} />;
  }

  if (itemType === "documentsList") {
    return <NotesButton onClick={setModalShow} linkText={"View"} />;
  }


  //if itemType is checkbox, return CheckboxGroup. All the logic for checkboxes will be handled in CheckboxGroup
  if (itemType === "checkbox") {
    return (
      <CheckboxGroup
        value={value}
        options={options}
        isEditing={isEditing}
        subTitle={subTitle}
        onChange={onChange}
      />
    );
  }

  if (isEditing) {
    switch (itemType) {
      case "dropdown":
        return (
          <DropdownInput
            value={value}
            onChange={onChange}
            options={options}
            isEditing={isEditing}
          />
        );
      case "string":
        return <TextInput value={value} onChange={onChange} />;
      default:
        return null;
    }
  }

  if (itemType === "dropdown") {
    return (
      <div
        className={`${
          hasError && "bp-grid-error-cell"
        }`}
      >
        <p className="bpgrid-value">{options[value]}</p>
        {hasError && <Warning errorMessage={errorMessage} />}
      </div>
    );
  }

  return (
    <div
      className={`${
        hasError && "bp-grid-error-cell"
      }`}
    >
      <p className="bpgrid-value">{value}</p>
      {hasError && <Warning errorMessage={errorMessage} />}
    </div>
  );
};

// Main Grid Component
//========================================================
const BPGrid = ({ children, numCols, style }) => (
  <div
    className="bp-table-grid bp-grid-default"
    style={{
      display: "grid",
      gridTemplateColumns: `repeat(${numCols}, 1fr)`,
      ...style,
    }}
  >
    {React.Children.map(children, (child) => React.cloneElement(child))}
  </div>
);

// Sub-components
//========================================================
BPGrid.Header = function Header({ children }) {
  return <div className="bp-table-grid-header bp-grid-default">{children}</div>;
};

BPGrid.Row = function Row({
  title,
  subTitle,
  value,
  isEditingValue,
  valueType,
  dropdownValues,
  onChange,
  hasError,
  errorMessage,
  setModalShow,
  tableTitle,
}) {
  return (
    <>
      <div className="bp-table-grid-header bp-grid-default">{subTitle}</div>
      <div className="bp-table-grid-cell bp-grid-default">
        <RenderElement
          subTitle={subTitle}
          value={value}
          isEditing={isEditingValue}
          itemType={valueType}
          options={dropdownValues}
          onChange={(newValue) => onChange(subTitle, newValue, tableTitle)}
          hasError={hasError}
          errorMessage={errorMessage}
          setModalShow={setModalShow}
        />
      </div>
    </>
  );
};

// PropTypes and DefaultProps
//========================================================
Warning.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

DropdownInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

NotesButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

CheckboxGroup.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

RenderElement.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isEditing: PropTypes.bool.isRequired,
  itemType: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  setModalShow: PropTypes.func,
};

RenderElement.defaultProps = {
  options: [],
  hasError: false,
  errorMessage: "",
  setModalShow: () => {},
};

BPGrid.propTypes = {
  children: PropTypes.node.isRequired,
  numCols: PropTypes.number.isRequired,
  style: PropTypes.object,
};

BPGrid.defaultProps = {
  style: {},
};

BPGrid.Header.propTypes = {
  children: PropTypes.node.isRequired,
};

BPGrid.Row.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isEditingValue: PropTypes.bool,
  valueType: PropTypes.string,
  dropdownValues: PropTypes.array,
  onChange: PropTypes.func,
  tableTitle: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  setModalShow: PropTypes.func,
};

BPGrid.Row.defaultProps = {
  title: "",
  subTitle: "",
  isEditingValue: false,
  valueType: "string",
  dropdownValues: [],
  onChange: () => {},
  tableTitle: "",
  hasError: false,
  errorMessage: "",
  setModalShow: () => {},
};

export default BPGrid;
