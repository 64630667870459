import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PdfViewer = ({ fileBuffer }) => {
  const pdfData = Uint32Array.from(fileBuffer);
  return (
    <div
      key={fileBuffer ? fileBuffer.length : 'no-buffer'} 
      style={{
        height: "750px",
        width: "900px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
          <Viewer fileUrl={pdfData} />
        </Worker>
      </div>
    </div>
  );
};

export default PdfViewer;