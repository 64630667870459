import React, { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import './navbar.css';
//import { withRouter } from "react-router-dom"
import { Navbar, Nav, NavDropdown, OverlayTrigger, Popover, PopoverContent, Container } from 'react-bootstrap'
import { ShowIf, useSecurity } from './Security/security';
import { useState, useEffect } from 'react';
import cabielogo from '../images/eori-white-logo.svg'
import tblogo from '../images/transbridge.svg'
import fllogo from '../images/fl.png'
import fllogo2 from '../images/fl2.png'
import tblogo1 from '../images/tb1.png'
import zelirLogo from '../images/zelir-logo.png'
import zelirLogo1 from '../images/zelir-logo1.png'
import chillChainLogo from '../images/Chill-Chain_Logo.png'
import favIconChillChain from '../images/cc-favicon.gif'
import dlLogo from '../images/dl-logo.jpg'
import favIconDl from '../images/dl-logo.jpg'
import ctlogo from '../images/ctlogo.png'
import ctlogo2 from '../images/fl2.png'
import lglLogo from '../images/lgl-logo.png'
import fav1 from '../images/favicon.ico'
import home from '../images/home.svg'
import homeLgl from '../images/new-home.png'
import ponsonbyLogo from '../images/ponsonby.png'
import alcalineLogo from '../images/alcaline.png'
import c3pllogo from '../images/C-3PLLogo.png'
import oaklandLogo from '../images/OaklandLogo.png'
import oakfav from '../images/oakfav.png'
import c3plfav from '../images/c3pl-favicon.jpg'

//tek
import tekFav from '../images/tek_fav.png'
import tekLogo from '../images/tek-blue-logo.svg'

import tm from '../images/registered-clear.svg'
import { StoreContext } from "./Store";
import isoDateConverter from "./isoDateConverter";

const NavbarMenu = () => {
const store = useContext(StoreContext);
const [logo, setLogo] = useState();
const [isCabie, setIsCabie] = useState(false);
const [mainFontColour, setMainFontColour]  = useState('#fff'); 
const { securityState } = useSecurity();
const [showNavMenu, setShowNavMenu] = useState(false);

  useEffect(() => {
    setShowNavMenu(true);
  }, [securityState]);

     useEffect(() => {
        try { switch (window.location.host) {


                  //-- Testing only
            

                  case "localhost":
                    setLogo(cabielogo);
                    store.showWebinars[1](true)
                    setIsCabie(true);  
                    import('./navbar-cb.css');  
                    document.getElementById('favicon').setAttribute('href', fav1) 
                    document.title = 'CABIE'
                  break

                //   case "localhost":
                //     setLogo(tekLogo);
                //     store.showWebinars[1](false)
                //     setIsCabie(false);  
                //     store.showAndFormatMainPageText[1]({left: '4rem', top: '45%'})
                //     import('./navbar-tk.css');  
                //     document.getElementById('favicon').setAttribute('href', tekFav) 
                //     setMainFontColour('#0078AB')
                //     document.title = 'Temperature controlled transport | TEK Freight' 
                // break


                
                  // case "localhost":
                  //   setLogo(alcalineLogo);
                  //   store.showWebinars[1](false)

                  //   setIsCabie(false);  
                  //   setMainFontColour('#000')
                  //   store.showAndFormatMainPageText[1](false) 
                  //   import('./navbar-al.css');  
                  //   document.getElementById('favicon').setAttribute('href', alcalineLogo) 
                  //   document.title = 'Alcaline'
                  // break
                  


                // DEV CABIE
                  case "dev.eoricustomtech.co.uk":
                    setLogo(cabielogo);
                    store.showWebinars[1](true)
                    setIsCabie(true);  
                    import('./navbar-cb.css');  
                    document.getElementById('favicon').setAttribute('href', fav1) 
                    document.title = 'CABIE'
                  break

                // DEV WLP - updated to TEK Freight
                  case "wdev.eoricustomtech.co.uk":
                    setLogo(tekLogo);
                    store.showWebinars[1](false);
                    setIsCabie(false);  
                    store.showAndFormatMainPageText[1]({left: '4rem', top: '45%'})
                    import('./navbar-tk.css');  
                    document.getElementById('favicon').setAttribute('href', tekFav) 
                    setMainFontColour('#0078AB')
                    document.title = 'Temperature controlled transport | TEK Freight' 
                    break

                  // =============== U A T ====================================================

                  // UAT - WLP - updated to TEK Freight
                  case "floraline.azurewebsites.net": // uat
                    setLogo(tekLogo);
                    store.showWebinars[1](false)
                    setIsCabie(false);  
                    store.showAndFormatMainPageText[1]({left: '4rem', top: '45%'})
                    import('./navbar-tk.css');  
                    document.getElementById('favicon').setAttribute('href', tekFav) 
                    setMainFontColour('#0078AB')
                    document.title = 'Temperature controlled transport | TEK Freight'  
                  break
                 
                  // UAT - CABIE
                  case "https://eori-portal-test.azurewebsites.net": // uat
                    setLogo(cabielogo);
                    store.showWebinars[1](true)
                    setIsCabie(true);  
                    import('./navbar-cb.css');  
                    document.getElementById('favicon').setAttribute('href', fav1) 
                    document.title = 'CABIE' 
                  break

                  //====================  P R O D  ================================================

                  // Chill-Chain

                    case "chillchain.azurewebsites.net":
                    case "chillchain.cabie.uk":
                      setLogo(chillChainLogo);
                      setIsCabie(false);
                      import('./navbar-cc.css');
                      document.getElementById('favicon').setAttribute('href', favIconChillChain)
                      document.title = 'Chill-Chain'
                    break

                  // Tek Freight
            
                  case "tekfreight.cabie.uk":
                    setLogo(tekLogo);
                    store.showWebinars[1](false)
                    setIsCabie(false);  
                    store.showAndFormatMainPageText[1]({left: '4rem', top: '45%'})
                    import('./navbar-tk.css');  
                    document.getElementById('favicon').setAttribute('href', tekFav) 
                    setMainFontColour('#0078AB')
                    document.title = 'Temperature controlled transport | TEK Freight'  
                  break
            
                  // floralinc
            
                  case "floralinc.cabie.uk":
                    setLogo(fllogo);
                    setIsCabie(false);
                    import('./navbar-fl.css');
                    document.getElementById('favicon').setAttribute('href', fllogo2)
                    document.title = 'FloraLinc'
                  break

                  // preprod

                  case "cabie-preprod.azurewebsites.net":
                    setLogo(cabielogo);
                    store.showWebinars[1](true)
                    setIsCabie(true);  
                    import('./navbar-cb.css');  
                    document.getElementById('favicon').setAttribute('href', fav1) 
                    document.title = 'CABIE'
                  break

                   // zelir  
            
                  case "zelir.eoricustomtech.co.uk":
                  case "zelir.cabie.uk":
                    setLogo(zelirLogo);
                    setIsCabie(false)
                    import('./navbar-zr.css');
                    document.getElementById('favicon').setAttribute('href', zelirLogo1)
                    document.title = 'ZELIR LOGISTICS'
                  break

                   // LGL  

                  case "lglworldwide.cabie.uk":
                    setLogo(lglLogo);
                    store.showWebinars[1](false)
                    setIsCabie(false);  
                    setMainFontColour('#000')
                    import('./navbar-lgl.css');  
                    document.getElementById('favicon').setAttribute('href', lglLogo) 
                    document.title = 'International Air Freight Services | LGL WorldWide Logistics'
                    break

                     // capital transport

                    case "capitaltransport.cabie.uk":
                      setLogo(ctlogo);
                      setIsCabie(false);
                      import('./navbar-ct.css');
                      document.getElementById('favicon').setAttribute('href', ctlogo)
                      document.title = 'Captial Transport'
                    break

                    // Theunisse-expeditie

                    case "theunisse-expeditie.cabie.uk":
                      setLogo(dlLogo);
                      store.showWebinars[1](false)
                      setIsCabie(false);  
                      setMainFontColour('#000')
                      store.showAndFormatMainPageText[1](false)
                      import('./navbar-dl.css');  
                      document.getElementById('favicon').setAttribute('href', favIconDl) 
                      document.title = 'Theunisse Expeditie'
                    break

                    // Ponsonby
                    case "ponsonby.cabie.uk":
                      setLogo(ponsonbyLogo);
                      store.showAndFormatMainPageText[1](false)
                      setIsCabie(false);  
                      import('./navbar-pp.css');  
                      document.getElementById('favicon').setAttribute('href', ponsonbyLogo) 
                      document.title = 'Paul Ponsonby'
                    break


                    case "alcaline.cabie.uk":
                      setLogo(alcalineLogo);
                      store.showWebinars[1](false)
                      setIsCabie(false);  
                      setMainFontColour('#000')
                      store.showAndFormatMainPageText[1](false)
                      import('./navbar-al.css');  
                      document.getElementById('favicon').setAttribute('href', alcalineLogo) 
                      document.title = 'Alcaline'
                    break

                    // C-3PL
                    case "complete3pl.cabie.uk":
                      setLogo(c3pllogo);
                      store.showWebinars[1](false)
                      setIsCabie(false);  
                      store.showAndFormatMainPageText[1](false)
                      import('./navbar-cp.css');  
                      document.getElementById('favicon').setAttribute('href', c3plfav) 
                      document.title = 'C-3PL' 
                    break

                    // Oakland
                    case "oakland-international.cabie.uk":
                      setLogo(oaklandLogo);
                      store.showWebinars[1](false)
                      setIsCabie(false);  
                      store.showAndFormatMainPageText[1](false)
                      import('./navbar-oa.css');  
                      document.getElementById('favicon').setAttribute('href', oakfav) 
                      setMainFontColour('#000')
                      document.title = 'Oakland International' 
                    break
            
                  default:
                    setLogo(cabielogo);
                    store.showWebinars[1](true)
                    setIsCabie(true);  
                    import('./navbar-cb.css');  
                    document.getElementById('favicon').setAttribute('href', fav1) 
                    document.title = 'CABIE'
                  break
              }
        } catch (err) {
      } 
    }, [])

  return (
    <div >
      <Navbar expand="lg" className={mainFontColour !== '#fff' ? 'shadow shadow-bottom shadow-lg' : ''}>
        {/* <img src={eorilogo}  alt="EORI Logo" />  */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="container-fluid">
          <Navbar.Brand as={RouterLink} className='eoriLogo' to="/"><span><img src={logo}  alt="CABIE Logo" />
          {/* <span style={{color:"#fff", fontSize:"0.5rem" , marginLeft: "0.4rem", marginBottom: "2rem"}}>TM</span> */}
         
          {isCabie && (<span><img src={tm} style={{height: "1.2rem", width:"1rem", marginLeft: "0.4rem", marginBottom: "2rem"}} alt="tm" /></span>)}

          {!isCabie && (
             <span style={{color: mainFontColour, marginLeft: '30px', fontSize: '12pt'}}><span className='logo-first-letter' style={{fontSize: '12pt'}}>P</span>
             <span style={{fontSize: '9pt'}}>owered</span> <span style={{fontSize: '12pt'}}>b</span><span style={{fontSize: '9pt'}}>y </span><span className='logo-first-letter' style={{fontSize: '12pt'}}>C</span><span style={{fontSize: '9pt'}}>ABIE</span>
             <span>
               {/* (R) symbol */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{height: "0.9rem", width:"0.9rem", marginLeft: "0.2rem", verticalAlign: 'text-top'}}>
                  <path style={{textIndent: "0px", textAlign: "start", lineHeight: "normal", textTransform: "none", fill: mainFontColour}} 
                      d="M 12 2 C 6.4889972 2 2 6.4889972 2 12 C 2 17.511003 6.4889972 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889972 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 8.5 7 L 8.5 17 L 10.59375 17 L 10.59375 13.40625 L 12.1875 13.40625 L 14.09375 17 L 16.3125 17 L 16.3125 16.90625 L 14.09375 12.8125 C 14.29375 12.7125 14.6125 12.60625 14.8125 12.40625 C 15.1125 12.20625 15.20625 12.0125 15.40625 11.8125 C 15.60625 11.5125 15.7125 11.3 15.8125 11 C 15.9125 10.7 15.90625 10.4 15.90625 10 C 15.90625 9.5 15.79375 9.0875 15.59375 8.6875 C 15.49375 8.3875 15.20625 8.1125 14.90625 7.8125 C 14.50625 7.5125 14.1875 7.2875 13.6875 7.1875 C 13.2875 7.0875 12.7875 7 12.1875 7 L 8.5 7 z M 10.6875 8.8125 L 12.3125 8.8125 C 12.8125 8.8125 13.2 8.8875 13.5 9.1875 C 13.8 9.4875 13.90625 9.8125 13.90625 10.3125 C 13.90625 10.5125 13.9125 10.70625 13.8125 10.90625 C 13.7125 11.10625 13.6 11.30625 13.5 11.40625 C 13.4 11.50625 13.2 11.5875 13 11.6875 C 12.8 11.7875 12.6125 11.8125 12.3125 11.8125 L 10.6875 11.8125 L 10.6875 8.8125 z" 
                      overflow="visible" 
                      enableBackground="accumulate" 
                      fontFamily="Bitstream Vera Sans"/>
              </svg>
</span></span>)}
          </span></Navbar.Brand>
          {showNavMenu && (sessionStorage.getItem('reg') === 'true') && (
           <ShowIf isLoggedIn>
           <Nav.Link className="NavLink-push-left" as={RouterLink} to="/main"  style={{ marginBottom: '1.6rem' }}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.3rem', color: mainFontColour }} viewBox="0 0 20 20" fill="currentColor">
                 <path fillRule="evenodd" d="M15.45,7L14,5.551V2c0-0.55-0.45-1-1-1h-1c-0.55,0-1,0.45-1,1v0.553L9,0.555C8.727,0.297,8.477,0,8,0S7.273,0.297,7,0.555  L0.55,7C0.238,7.325,0,7.562,0,8c0,0.563,0.432,1,1,1h1v6c0,0.55,0.45,1,1,1h3v-5c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1v5h3  c0.55,0,1-0.45,1-1V9h1c0.568,0,1-0.437,1-1C16,7.562,15.762,7.325,15.45,7z" clipRule="evenodd"/>
              </svg>
            </Nav.Link>
           <ShowIf or={[{department: "OPS"}, {department: "DCT"}, {department: "TRADER"}, {department: "LSP"}, {department: "WLP"}]}>
               <NavDropdown title="Create Load" id="basic-nav</svg>-dropdown">
                 <ShowIf or={[{department: "OPS"}, {department: "DCT"}]}>
                   <NavDropdown.Item as={RouterLink} to="/Newload">Build your load</NavDropdown.Item>
                   <NavDropdown.Item as={RouterLink} to="/NewLoadFileUpload">Import your load</NavDropdown.Item>
                 </ShowIf>


                 <ShowIf or={[{department: "TRADER"}, {department: "LSP"}, {department: "WLP"}]}>


                   <NavDropdown.Item as={RouterLink} to="/EpoExports">
                     <ShowIf or={[{department: "TRADER"}, {department: "LSP"}, {department: "WLP"}]}>
                       New Load
                     </ShowIf>
                     <ShowIf or={[{department: "CMT"}, {department: "FIN"}]}>
                       EPO
                     </ShowIf>
                   </NavDropdown.Item>
                   {/* <NavDropdown.Item as={RouterLink} to="/EpoExports">
                     <ShowIf or={[{department: "TRADER"}, {department: "OPS"}, {department: "DCT"}, {department: "LSP"}]}>
                       EPO
                     </ShowIf>
                   </NavDropdown.Item> */}
                 </ShowIf>
               </NavDropdown>
             </ShowIf>

            

             {/* <ShowIf or={[{department: 'TRADER'}, {department: 'OPS'}, {department: 'CMT'}, {department: 'DCT'}]}>
               <NavDropdown title="Data Management" id="basic-nav-dropdown">
                 <ShowIf>
                   <NavDropdown.Item as={RouterLink} to="/CustomerManagement">Customer Management</NavDropdown.Item>
                 </ShowIf>
                 <NavDropdown.Item as={RouterLink} to="/DataValidation">Tariff Tool</NavDropdown.Item>
               </NavDropdown>  
             </ShowIf> */}


             {/* <Nav.Link as={RouterLink} className={(isDummy ? 'd-block' : 'd-none')} to="/maintenance">Maintenance</Nav.Link> */}



             <ShowIf or={[{department: "OPS"}, {department: "DCT"}, {department: "CMT"}, {department: "TRADER"}, {department: 'FIN'},{department: 'LSP'}, {department: "WLP"}]}>
               <NavDropdown title="View Loads">
                 <ShowIf or={[{department: "OPS"}, {department: "DCT"}]}>
                   <NavDropdown.Item as={RouterLink} to="/ListLoads">CABIE Loads</NavDropdown.Item>
                 </ShowIf>
                 <ShowIf or={[{department: "OPS"}, {department: "DCT"}, {department: "TRADER"}, {department: 'FIN'}, {department: 'LSP', }, {department: "WLP"}]}>
                   <NavDropdown.Item as={RouterLink} to="/EpoListLoads">
                   
                     <ShowIf or={[{department: "TRADER"}, {department: "LSP"}, {department: "WLP"}]}>
                       Loads
                     </ShowIf>
                     <ShowIf or={[{department: "CMT"}, {department: "OPS"}, {department: "DCT"}, {department: "FIN"}]}>
                       EPO Loads
                     </ShowIf>

                   </NavDropdown.Item>
                 </ShowIf>
                 {/* <ShowIf or={[{department: "WLP"}]}>
                   <NavDropdown.Item as={RouterLink} to="/WlpLoads">Loads</NavDropdown.Item>
                 </ShowIf> */}
               </NavDropdown>
             </ShowIf>

             <ShowIf or={[{department: 'OPS', role: "SUPERUSER"}, {department: 'FIN'}, {department: 'IT', role: "SUPERUSER"}]}>
             {/* <NavDropdown title="Tools" id="basic-nav-dropdown"> */}
                   <Nav.Link as={RouterLink} to="/ReportsList">Reports</Nav.Link>
                   {/* </NavDropdown> */}
                 </ShowIf>


             <ShowIf or={[{department: "OPS"}, {department: "DCT"}, {department: 'CMT'}]}>
               <NavDropdown title="Master Data" id="basic-nav-dropdown">
                 <ShowIf or={[{department: "OPS"}, {department: "DCT"}, {department: "CMT"}]}>
                   <NavDropdown.Item as={RouterLink} to="/Neenor">NeeNor</NavDropdown.Item>
                   <NavDropdown.Item as={RouterLink} to="/Products">Products</NavDropdown.Item>
                   <NavDropdown.Item as={RouterLink} to="/BusinessPartners">Business Partners</NavDropdown.Item>
                 </ShowIf>
               </NavDropdown>
             </ShowIf>


             <ShowIf or={[{department: "OPS"}, {department: "DCT"}, {department: 'TRADER'}, {department: 'LSP'}, {department: 'WLP'}]}>
               <NavDropdown title="Tools" id="basic-nav-dropdown">
               <ShowIf or={[{department: 'TRADER'}, {department: 'OPS'}, {department: 'CMT'}, {department: 'DCT'}, {department: 'LSP'}, {department: 'WLP'}]}>
                     <NavDropdown.Item as={RouterLink} to="/DataValidation">Tariff Tool</NavDropdown.Item>
                     <NavDropdown.Item href="https://ec.europa.eu/taxation_customs/dds2/eos/rex_validation.jsp?Lang=en" target="_blank">REX Number Validation</NavDropdown.Item>
                     <NavDropdown.Item href="https://view.officeapps.live.com/op/view.aspx?src=https://8001340.fs1.hubspotusercontent-na1.net/hubfs/8001340/BTOM_SPS_risk_categories_Nov23.xlsx" target="_blank">SPS Risk Checker</NavDropdown.Item> 
                     </ShowIf>
               </NavDropdown>
             </ShowIf>


             <ShowIf or={[{department: "CMT"}, {department: 'FIN'}, {department: 'OPS'}, {department: 'TRADER'}, {department: 'LSP'},
            {department: 'WLP'}, {department: 'IT'}]}>
               <NavDropdown title="Admin">
                  <ShowIf isAdmin>
                    <NavDropdown.Item as={RouterLink} to="/UserAdmin">User Admin</NavDropdown.Item>
                  </ShowIf>

                 <ShowIf or={[{department: 'IT'}]}>
                   <NavDropdown.Item as={RouterLink} to="/OnBoarding">Onboarding</NavDropdown.Item>
                    <NavDropdown.Item as={RouterLink} to="/UserAdminAudit">Audit</NavDropdown.Item>
                    <NavDropdown.Item as={RouterLink} to="/UserAdminEPO">EPO</NavDropdown.Item>
                    <NavDropdown.Item as={RouterLink} to="/UserAdminCommcodes">Commodity Codes</NavDropdown.Item>
                 </ShowIf>

                 <ShowIf or={[{department: 'CMT'}, {department: 'LSP'}, {department:"TRADER", role:"SUPERUSER"}]}>
                   <NavDropdown.Item as={RouterLink} to="/Userprofile">Company Profile</NavDropdown.Item>
                 </ShowIf>
                 {/* <ShowIf department="TRADER">
                  <NavDropdown.Item as={RouterLink} to="/NominatedUsers">Nominate User</NavDropdown.Item>
                  </ShowIf> */}
                  <ShowIf or={[{department: 'FIN'}, {department: "CMT"}, {department: "OPS"}]}>
                   <NavDropdown.Item as={RouterLink} to="/EpoApplications">EPO Applications</NavDropdown.Item>
                 </ShowIf>
                 <ShowIf department="WLP">
                   <NavDropdown.Item as={RouterLink} to="/WlpTrader">Traders</NavDropdown.Item>
                 </ShowIf>
                 <ShowIf or={[{department: 'OPS', role: "SUPERUSER"}, {department: 'WLP'}]}>
                   <NavDropdown.Item as={RouterLink} to="/Register">Register Customer</NavDropdown.Item>
                 </ShowIf>
                 {/* <ShowIf or={[{department: 'IT'}]}>
                   <NavDropdown.Item as={RouterLink} to="/SCUpload">Short Code Upload</NavDropdown.Item>
                 </ShowIf> */}
               </NavDropdown>            
              </ShowIf> 
          
             <div style={{ flexGrow: 1 }} />            
              <div className="gap-2 align-items-center d-flex" style={{ marginBottom: '2.3rem' }}>
                <OverlayTrigger
                  placement="left"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover clasName="shadow" style={{ minWidth: "320px", padding: "10px", paddingBottom: "1rem"}}>
                      <PopoverContent>
                        <h5>User Details</h5>
                        <hr/>
                        <div><span className="cci-header" style={{marginRight: '10px'}}>Name:</span><span className="pad-out">{sessionStorage.getItem('fullName')}</span></div>
                        <div><span className="cci-header" style={{marginRight: '10px'}}>Email:</span><span className="pad-out">{sessionStorage.getItem('name')}</span></div>
                        <div><span className="cci-header" style={{marginRight: '10px'}}>Department:</span><span className="pad-out">{sessionStorage.getItem('department')}</span></div>
                        <div><span className="cci-header" style={{marginRight: '10px'}}>Role:</span><span className="pad-out">{sessionStorage.getItem('role')}</span></div>
                        <div><span className="cci-header" style={{marginRight: '10px'}}>Admin:</span><span className="pad-out">{JSON.parse(sessionStorage.getItem('admin')) ? "Yes" : "No"}</span></div>
                        { JSON.parse(sessionStorage.getItem('trader')) && (<div><span className="cci-header" style={{marginRight: '10px'}}>Trader:</span><span className="pad-out">{sessionStorage.getItem('trader') ? "Yes" : "No"}</span></div>)}
                        {/* { JSON.parse(sessionStorage.getItem('lsp'))  && (<div><span className="cci-header" style={{marginRight: '10px'}}>LSP:</span><span className="pad-out">{sessionStorage.getItem('lsp') ? "Yes" : "No"}</span></div>)} */}
                        <div><span className="cci-header" style={{marginRight: '10px'}}>Last Logged in:</span><span className="pad-out">{isoDateConverter(sessionStorage.getItem('lastLoggedIn'))}</span></div>
                      </PopoverContent>
                    </Popover>
                  }
                >
                 <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.3rem', color: mainFontColour }} viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
                {/* <span style={{ marginRight: "1rem", fontSize: "1rem", color: '#ffbe00'  }}>{sessionStorage.getItem('fullName')}</span> */}
               </OverlayTrigger>
              </div>
              
              <Nav.Item style={{ marginRight: "1.3rem" }}>
                <Nav.Link as={RouterLink} to="/logout" className="navbar-text" >Logout</Nav.Link>
              </Nav.Item>
            </ShowIf>
          )}

        <ShowIf isLoggedIn={true}>
              {(sessionStorage.getItem('reg') === 'false') && (
                  <>
                  <div style={{ flexGrow: 1 }} />
                  <div className="gap-2 align-items-center d-flex" style={{ marginBottom: '2.4rem' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.3rem', color: mainFontColour }} viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                    </svg>
                  <span style={{ marginRight: "1rem", fontSize: "1rem", color: '#ffbe00'  }}>{sessionStorage.getItem('name')}</span>
                  </div> 

                  <Nav.Item style={{ marginRight: "1.3rem" }}>
                    <Nav.Link as={RouterLink} to="/logout" className="navbar-text" >Logout</Nav.Link>
                  </Nav.Item>
                  </>)} 
          </ShowIf>                   
            <ShowIf isLoggedIn={false}>
              <div style={{ flexGrow: 1 }} />
              <div className="gap-2 align-items-center d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.3rem', color: mainFontColour }} viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
                <Nav.Link as={RouterLink} to="/Login" className="d-inline-flex align-items-center justify-content-center" style={{color: mainFontColour}}>Login</Nav.Link>
                <div style={{ pointerEvents: 'none', width: '0.01rem', height: '0.9rem', backgroundColor: mainFontColour }} className="rounded"
                >&nbsp;</div>
                <Nav.Link as={RouterLink} to="/Register" className="d-inline-flex gap-2 align-items-center justify-content-center" style={{color: mainFontColour}}>
                  Signup
                </Nav.Link>
              </div>
            </ShowIf>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

    </div>
  );
}

export default NavbarMenu;

