import React, { useState, useEffect, useContext } from "react";
//import { withRouter } from "react-router-dom";
import "./table.css";
import tableHeaders from "./tableHeaders";
import TableDetails from "./TableDetails";
import { postNewloadLazyLoad } from "./apiCalls";
import { Input } from "./Input/Input.js";
import { ArrowUp, Dolly, ExternalLinkAlt, Paperclip } from "@styled-icons/fa-solid";
import { getReports } from "./apiCalls";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Pagination, ProgressBar, Table } from "react-bootstrap";
import DataTable, {useDataTableHandle} from "./DataTable";

import UploadIcon from "../images/Upload.svg";
import { StoreContext } from "./Store";

const ITEMS_PER_PAGE = 10;

const DisplayLoads = () => {
  let dashboardFilterParam = ""

  const [invoices, setInvoices] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [requestFailed, setRequestFailed] = useState(false);
  const [paginationLoaded, setPaginationLoaded] = useState(false);
  const [dashboard, setDashboard] = useState(null);

  const store = useContext(StoreContext);

  const navigate = useNavigate();

  const dth = useDataTableHandle();

  const statusOptions = ["Draft", "Submitted", "In progress", "Completed"].map((s) => {return {data: s, id: s}})

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setDashboard(urlParams.get('dashboard') !== null ? urlParams.get('dashboard') : "");
  }, [])

  useEffect(() => {
    if (paginationLoaded && dashboard !== null) 
      fetchData();
  }, [paginationLoaded, dashboard]);

  async function fetchData() {
    try {
      const data = await postNewloadLazyLoad({
        skip: dth.current.pagination.pageNo * dth.current.pagination.itemsPerPage, 
        amount: dth.current.pagination.itemsPerPage,
        sort_by: dth.current.sortStatus.currentKey,
        sort_order: dth.current.sortStatus.direction,
        dashboardElement: dashboard
      });
      dth.current.setServerDataLength(data.totalItems)
      setInvoices(data.data);
    } catch (err) {
      setRequestFailed(true);
    }
  }

  async function updateData(setPaginationLength, pagination, sortStatus, search, filter) {
    try {
      //read the parameter from the url and assign it to the dashboardFilterParam
      const urlParams = new URLSearchParams(window.location.search);
      dashboardFilterParam = urlParams.get('dashboard');

      const data = await postNewloadLazyLoad({
        skip: pagination.pageNo * pagination.itemsPerPage, 
        amount: pagination.itemsPerPage,
        sort_by: sortStatus.currentKey,
        sort_order: sortStatus.direction,
        search,
        filter,
        dashboardElement: dashboard
      })
      setPaginationLength(data.totalItems)
      setInvoices(data.data);
    }
    catch (err) {
      setRequestFailed(true);
    }

  }

  const handleReset = () => {
    setDashboard("");
  }

  function clearSearch() {
    document.querySelector("#listload-search-form").reset();
    fetchData();
  }

  function gotoDocumentUploadPage(id) {
    store.newLoadID[1](id);
    navigate('/AdditionalFileUpload')
  }

  function gotoDocumentsUploadedPage(id) {
    store.newLoadID[1](id);
    navigate('/AdditionalFileDisplay')
  }

  if (requestFailed) return <p>Failed!</p>;

  return (
    <div className="full-width-container">
      <section className="floating-box" style={{ maxWidth: "1500px" }}>
        <div className="floating-box-header-general">
          <span>Loads</span>
        </div>
        <div>
          <div className="px-3 pt-4">
          <DataTable showAdvnSearch={true} data={invoices} handle={dth} className="table-striped" filterable sortable lazyLoading advancedSearch onUpdate={updateData}
          onPaginationLoaded={() => {
            setPaginationLoaded(true);
          }} handleReset={handleReset}
          >
            <DataTable.Headers style={{fontSize: "14.4px", backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
            <DataTable.Row style={{fontSize: '15px'}} />
            <DataTable.Pagination itemsPerPage={[10, 25, 50]} />
            
            <DataTable.Column colKey="Ref No." header="Ref No." searchOpts={{type: "text", sendAs: "refno"}} />
            <DataTable.Column colKey="companyName" header="Customer Name" />
            <DataTable.Column colKey="eoriNumber" header="EORI" />
            <DataTable.Column colKey="Name" header="Name" searchOpts={{type: "text", sendAs: "name"}}/>
            
            <DataTable.Column colKey="Loading date" header="Loading Date" searchOpts={{type: "date", sendAs: "loadDate"}} sort={(a, b) => {
              if (!a) return 1;
              if (!b) return -1;
              const corrected = d => `20${d.slice(-2)}-${d.slice(3,5)}-${d.slice(0,2)}`;
              const aDate = new Date(corrected(a));
              const bDate = new Date(corrected(b));
              if (aDate > bDate) return 1;
              if (aDate < bDate) return -1;
              return 0;
            }} />
            <DataTable.Column colKey="Job reference" header="Job Ref" maxWidth="17ch" searchOpts={{type: "text", sendAs: "jobReference"}} />
            <DataTable.Column colKey="Status" header="Status" searchOpts={{type: "select", src: statusOptions, sendAs: "status"}} />
            <DataTable.Column colKey="Progress" header="Progress">
              {({ Progress }) => {
                if (!Progress) {
                  return <ProgressBar now={100} label="0%" style={{width: '6rem'}} variant="danger" />
                }
                let variant;
                if (Progress <= 50) {
                  variant = "warning";
                } else if (Progress < 100) {
                  variant = "info"
                } else if (Progress >= 100) {
                  variant = "success";
                }
                
                return <ProgressBar now={Progress} label={`${Progress}%`} style={{width: '6rem'}} variant={variant} />
              }}
            </DataTable.Column>
            {/* <DataTable.Column colKey="Documents" header="Docs" className="p-0" style={{ height: "1px",  }} headerStyle={{textAlign: 'center'}}>
              {({ id, Documents }) => (
                Documents > 0 ? (
                <button
                  className="external-table-link bold text-decoration-underline" style={{fontSize: '110%'}}
                  onClick={() =>gotoDocumentsUploadedPage(id)}
                >
                  {Documents}
                </button>) : (
                  <span className="tableButton px-2 d-flex justify-content-center align-items-center">
                    {Documents}
                  </span>
                )
              )}
            </DataTable.Column>
            <DataTable.Column header="Upload" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {({id}) => (
                <button className="external-table-link" onClick={() => gotoDocumentUploadPage(id)}>
                  <img src={UploadIcon} width="22" height="22" />
                </button>
              )}
            </DataTable.Column> */}
            <DataTable.Column className="p-0" style={{ height: "1px" }} header="View" headerStyle={{textAlign: 'center'}}>
            {({ id, Status }) => (
              <button
                className="external-table-link"
                onClick={() => {
                  console.log("Status:", Status);
                  navigate({
                    pathname: Status === 'Draft' ? `/Newload/${id}` : `/loads/${id}`,
                  });
                }}
              >
                <ExternalLinkAlt width="1rem" height="1rem" />
              </button>
)}

            </DataTable.Column>
          </DataTable>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DisplayLoads;
