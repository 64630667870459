import {
  getBusinessPartner,
  putBusinessPartner,
  getBusinessParnerDropdownValues,
  getBusinessParnerDocumentsList,
  getBusinessParnerDocumentDetails,
  getBusinessParnerDocumentDescriptionDropdown,
  getBusinessParnerDocumentTypeDropdown,
  postBusinessPartnerDocument,
} from "..//apiCalls";

/*this function will be responsible to fetch the data for the business partner, so we can populate the fields in the UI
params:
- title: string - the table title that we want to fetch the data for
- businessPartnerId: number - the id of the business partner we want to fetch the data for
- isTest: boolean - flag to indicate if we are in test mode or not. The default value is false
returns:
- data: object - the response from the API call, containing the data for the selected business partner
======================================================*/
export const fetchBusinessPartner = async (
  title,
  businessPartnerId,
  isTest = false
) => {

  //check if title is BPTraderNotes. If that's the case, we want to return a specific object and not make a call
  if(title === 'Trader Notes'){
    const returnData = [
      {subtitle: "Notes", value: '', Type:"notes"}, 
    ]
    return returnData;
  }

  //TODO: change to fetchBusinessPartner
  const data = await getBusinessPartner(title, businessPartnerId, isTest);
  //check if title is 'Authorisation Documents' and if it is, add a new object to the data array to handle the documents list
  if (title === "Authorisation Documents") {
    data.push({ subtitle: "Documents", value: '', Type:"documentsList" });
  }
  return data;
};

/*this function will be responsible to fetch the dropdown values, so we can populate the dropdowns in the UI
params: 
- dropdown: string - the name of the dropdown we want to fetch the values for
returns:
- response: object - the response from the API call, containing the values for the selected dropdown
======================================================*/
export const fetchBusinessPartnerDropdownValues = async (dropdown) => {
  const dropdownsToFetch = ["CABIE Status", "Secondary Status"];
  if (!dropdownsToFetch.includes(dropdown)) {
    return new Promise((resolve) => {
        resolve([
            {
                id: 1,
                data: "No",
                statusNumber: "0",
            },
            {
                id: 2,
                data: "Yes",
                statusNumber: "1",
            }
        ]);
    });
  } else {
    const response = await getBusinessParnerDropdownValues(dropdown);
    return response;
  }
};

/*this function will be responsible to save the business partner data
params:
- data: object - the data to be saved
- activeStep: number - the active step in the stepper
- businessPartnerId: number - the id of the business partner we want to save the data for
returns:
- response: object - the response from the API call, containing the company id and the possible validation errors for the data 
======================================================*/
export const saveBusinessPartner = async (
  data,
  activeStep,
  businessPartnerId = 1
) => {
  const response = await putBusinessPartner(
    data,
    activeStep,
    businessPartnerId
  );
  return response;
};

export const fetchBusinessPartnerDocuments = async (businessPartnerId) => {
  const response = await getBusinessParnerDocumentsList(businessPartnerId);
  const filteredResponse = response.map(({ id, Type, Description, Date, 'Added By': AddedBy, button }) => ({
    id,
    Type,
    Description,
    Date,
    'Added By': AddedBy,
    button
  }));
  return filteredResponse;
}

export const fetchBusinessPartnerDocumentDetails = async (documentId) => {
  const response = await getBusinessParnerDocumentDetails(documentId);
  //on the response[0], add a new key called 'data', which is going to get the value of the column 'envelope' if 'envelope' is not null, otherwise, it will get the value of the column 'form'
  response[0].data = response[0].envelope ? response[0].envelope : response[0].form;
  return response
}

export const fetchBusinessPartnerDocumentDescriptionValues = async () => {
  const response = await getBusinessParnerDocumentDescriptionDropdown();
  return response
}

export const fetchBusinessPartnerDocumentTypeValues = async () => {
  const response = await getBusinessParnerDocumentTypeDropdown();
  return response
}

export const uploadBusinessPartnerFile = async (data) => {
  const response = await postBusinessPartnerDocument(data);
  return response;
}