import React, { useState, useEffect, useContext, useMemo } from 'react';
import "../main.css";
import "./loaddetails.css";
import { getListLoad} from "../apiCalls";
import { StoreContext } from '../Store';
import DataTable from '../DataTable';
import { head } from 'lodash';
import { Col, Row } from "react-bootstrap";
import { Input } from '../Input/Input'
import GridTotals from '../GridTotals';

const LoadItems = (props) => {
  const store = useContext(StoreContext);
  const [items, setItems] = useState([])
  const [totals, setTotals] = useState({})
  const [requestFailed, setRequestFailed] = useState(false);
  const invoiceNumber = props.invoicenumber
  const headerData = props.headerData;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getListLoad('items', invoiceNumber)
        setItems(data.Newload[0].Items)
        setTotals({
          grossKG: data.Newload[0].grossKG,
          netKG: data.Newload[0].netKG,
          numberItems: data.Newload[0].numberItems,
          loadValue: data.Newload[0].loadValue.toFixed(2),
          itemRows: data.Newload[0].itemRows
        })
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [])

  const returnData = useMemo(() => {
    const vatOptions = store.VatOptions[0];
    const preOptions = store.PreferentialOptions[0];
  
    return items?.map((row) => {
      const vatOption = vatOptions.find(opt => parseInt(opt.id) === parseInt(row.VAT));
      const preOpt = preOptions.find(opt => parseInt(opt.id) === parseInt(row.preferential));

      row.VAT = vatOption ? vatOption.data : row.VAT;
      row.preferential = preOpt ? preOpt.data : row.preferential;      
      return row;
    });
  }, [items, store.VatOptions, store.PreferentialOptions]);

  if (requestFailed) return <p>Failed!</p>

  return (

    <div className="floating-box-newload loaditems">
    <div className='d-flex flex-row justify-content-between'>
      <div className='floating-box-header-newload loads '>
     <span>Load Items</span>
     </div>
       <div className="d-flex flex-column gap-2 justify-content-between align-items-end mb-4">
            <div className="d-flex flex-row">
              <div className='d-flex flex-column cabie-company-info' style={{marginRight: '10px'}} >
               <span className="cci-header"> Portal Ref: </span>
               {headerData.companyName && <span className="cci-header">Company Name: </span>}
               {headerData.eoriNumber && <span className="cci-header">EORI Number: </span>}
              </div>

              <div className='d-flex flex-column  cabie-company-info'>
              <span className='pad-out'>{headerData.portalRef}</span>
              {headerData.companyName && <span className='pad-out'> {headerData.companyName.slice(0,50)} </span>}
              {headerData.eoriNumber && <span className='pad-out'> {headerData.eoriNumber} </span>}
            </div>
          </div>
        </div>

      </div>

      <GridTotals 
        grossKG={totals.grossKG}
        netKG={totals.netKG}
        value={totals.loadValue}
        itemCount={totals.numberItems}
        rows={totals.itemRows}
        />
       
        <DataTable data={returnData} sortable filterable className="table-striped table-bordered" showLowerCaseHeaders={true}>
        <DataTable.Pagination itemsPerPage={[10, 25, 50]} />
        <DataTable.Headers style={{backgroundColor: 'white', fontSize: "13px"}}/>
        <DataTable.Row style={{fontSize: '14px'}} />
          <DataTable.Column colKey="HS Code (10)" header="HS Code" />
          <DataTable.Column colKey="Origin" header="Origin" />
          <DataTable.Column colKey="Description of goods" header="Description">
            {item => {
              return item["Description of goods"]?.slice(0, 12) + (item["Description of goods"]?.length > 12 ? "..." : "")
            }}
          </DataTable.Column>
          <DataTable.Column colKey="No of packages" header="No. Items" />
          <DataTable.Column colKey="Package" header="Type" />
          <DataTable.Column colKey="Gross mass (kg)" header="Gross Mass" />
          <DataTable.Column colKey="Net mass (kg)" header="Net Mass" />
          <DataTable.Column colKey="Item value" header="Value" />
          <DataTable.Column colKey="Qty 2" header="Qty 2" />
          <DataTable.Column colKey="Health cert" header="Health cert">
            {/* {item => item["Health cert"] ? item["Health cert"].slice(0, 18) : ''} */}
          </DataTable.Column>
          <DataTable.Column colKey="Catch cert" header="Catch cert">
            {/* {item => item["Health cert"] ? item["Health cert"].slice(0, 18) : ''} */}
          </DataTable.Column>
          <DataTable.Column colKey="Meursing" header="Meursing" />
          <DataTable.Column colKey="Organic" header="Organic" />
          <DataTable.Column colKey="VAT" header="VAT" />
          <DataTable.Column colKey="RGR" header="RGR" />
          <DataTable.Column colKey="preferential" header="Pref" />
        </DataTable>
        <div style={{ flexGrow: 1 }} />
        <div className='d-flex justify-content-between'>
        <button className="blue-button-newload stepper-button yellow" onClick={props.prevPage}>Details</button>
        <button className="blue-button-newload stepper-button yellow" onClick={props.nextPage}>Status</button>
        </div>
      </div>
     
  )
}

export default LoadItems;