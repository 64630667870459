import { useContext } from "react";
import {BusinessPartnerContext } from './BusinessPartnerCard'

const BPHeader = () => {
  const {
    companyName,
    eoriNumber
  } = useContext(BusinessPartnerContext);
  return (
    <div className="bp-companydetails-header">
      <div className="cabie-company-info">
        <p><span className="label">Company Name:</span>{companyName}</p>
        <p><span className="label">EORI Number:</span>{eoriNumber}</p>
      </div>
    </div>
  );
};

export default BPHeader;
