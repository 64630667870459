/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../Store";
import "./newLoad.css";
import "./orderForm.css";
import { getNewLoadDetails, postOrderForm } from "../apiCalls";
import ControlledGoodsModal from "../ControlledGoodsModal";
//import ClientIDconfirmModal from "./ClientIDconfirmModal";
import { Input, Switch } from "../Input/Input"
import { Col, Collapse, OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import _ from "lodash";
import { useSecurity } from "../Security/security";

const OrderForm = (props) => {

 
  const store = useContext(StoreContext);
  const { validateRole } = useSecurity();
  const [requestFailed, setRequestFailed] = useState(false);
  const [orderFormMovementDetails, setOrderFormMovementDetails] = useState({});
  const [despatchCountry, setDespatchCountry] = useState("");
  const [exitPort, setExitPort] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [entryPort, setEntryPort] = useState("");
  const [entryPortOrderForm, setEntryPortOrderForm] = useState([]);
  const [exitPortOrderForm, setExitPortOrderForm] = useState([]);
  const [showControlledGoodsModal, setShowControlledGoodsModal] = useState(false);
  const [noneSelected, setNoneSelected] = useState(false);
  const [diableSubmit, setDisableSubmit] = useState(true); 
  const [allChecked, setAllChecked] = useState(false);
  //const [showClientIDconfirmModal, setShowClientIDconfirmModal] = useState(false);

  const handleClick = (() => {
    if ((store.GBimport[0] && store.controlledGoods[0] ==='N') && !noneSelected ){
      setShowControlledGoodsModal(true)
    } else {
      if (validateRole({isCustomer: true}) || !store.clientID[0] || store.newLoadFromFile[0])
        props.submit(window.event);
      else
       // setShowClientIDconfirmModal(true);
      props.submit(window.event);
      setNoneSelected(false)
    }
  });

  useEffect(() => {
    setDespatchCountry(
      findById(store.despatchCountryOptions[0], store.despatch[0])
    );
    setExitPort(findById(store.exitPortOptions[0], store.exit[0]));
    setDestinationCountry(
      findById(store.entryCountryOptions[0], store.destination[0])
    );
    setEntryPort(findById(store.entryPortOptions[0], store.entry[0]));
  }, [store]);

  useEffect(() => {
    (async () => {
      if (!store.directionSelected[0]) return; 
      try {
        const [data] = await getNewLoadDetails(
          "details",
          store.directionSelected[0]
        );
        setOrderFormMovementDetails(data);
      } catch (err) {
        setRequestFailed(true);
      }
    })();
  }, [store.directionSelected[0]]);

  useEffect(() => {
    (async () => {
      if (store.entry[0] === null) return;
      try {
        const entry = await getNewLoadDetails("portprocess", store.entry[0]);
        setEntryPortOrderForm(entry);
      } catch (err) {
        setRequestFailed(true);
      }
    })();
  }, [store.entry[0]]);

  useEffect(() => {
    (async () => {
      if (store.exit[0] === null) return;
      try {
        const exit = await getNewLoadDetails("portprocess", store.exit[0]);
        setExitPortOrderForm(exit);
      } catch (err) {
        setRequestFailed(true);
      }
    })();
  }, [store.exit[0]]);

  const findById = (arr, id) => {
    const result = arr.find((item) => item?.id?.toString() === id?.toString());
    if (result) return result;
    return {};
  };

  if (requestFailed) throw new Error("Failed to retrieve port processes from server.");

  const fetchNewDocument = async () => {
    const anyCerts = store.validatedRowsReadyToSubmit[0].some(obj => typeof obj.healthCert === "string" ? (obj.healthCert.length > 5 && (obj.healthCert.toUpperCase() !== "NOT REQUIRED" ) && (obj.healthCert.toUpperCase() !== "EXEMPT" )) : null);
    const postData = new FormData();
    postData.append("directionid", store.directionSelected[0] ?? "");
    postData.append("controlledGoods", store.controlledGoods[0]);
    postData.append("healthCert", anyCerts ? "Yes" : "No"); // TODO: may change to "YES", "NO", or something else.
    postData.append("officeOfTransit", store.officeOfDest[0] ? "Yes" : "No");

    postData.append("port1", (store.RowImport[0] ? "" : store.exit[0]));
    postData.append("port2", (store.RowExport[0] ? "" : store.entry[0]));

     postData.append("port3", store.exit2[0] ?? "");
     postData.append("port4", store.entry2[0] ?? "");

    postData.append("gvms", store.portsModal[0] ?? "");
    postData.append("commCodes", JSON.stringify(store.validatedRowsReadyToSubmit[0]));
    
    const results = await postOrderForm(postData);
    store.docTypes[1](results.map(document => ({...document, checked: false})));
  };


  useEffect(() => {
    if (props.visible) {
      fetchNewDocument();
    }
  }, [props.visible]);

  
  useEffect(() => {
  }, [store.directionSelected[0], store.controlledGoods[0], store.officeOfDest[0], store.validatedRowsReadyToSubmit[0]])

  useEffect(() =>{
    const isAnyChecked = store.docTypes[0].some(form => form.checked);
    isAnyChecked ? setDisableSubmit(false) : setDisableSubmit(true)
  },[store?.docTypes[0]])

  /**
   * Updates a document in the `documents` variable.
   * @param {Number} idx index of document that is being updated
   * @param {Object} newDocument Values to replace old values with in document.
   */
  function setDocumentsByIndex(idx, newDocument) {
    store.docTypes[1](prev => {
      prev[idx] = {...prev[idx], ...newDocument};
      return [...prev];
    });
  }

  return (
    <div className="mb-4 semi-fluid-container container-lg">
    <section className="floating-box-newload">
      <div className="floating-box-header-orderForm">
        <span>Order Form {!!store.clientID[0] && !validateRole({isCustomer: true}) && (<span style={{fontSize: '1.0rem', marginTop: '0.3rem'}}>({store.clientIDname[0]})</span>)}</span>
        <span style={{marginTop: "10px", fontSize: "1.2rem", fontWeight: "500", }}>Route: {findById(store.movementOptions[0], store.directionSelected[0]).data}</span>
      </div>
      <div>
        <div className="d-flex flex-column bold" style={{borderBottom: "solid 1px #00000033", borderTop: "solid 1px #00000033"}}>
      <div className="d-flex gap-2 align-items-center my-3">
      <div style={{maxWidth: '75px', minWidth: '75px'}} className="align-self-start px-2 ms-3">
        Include
      </div>
      <Col xs="2" style={{minWidth: "20rem"}}>
        Document
      </Col>
      <div style={{minWidth: "5rem"}}>
        Location
      </div>
      <div className="ms-5">
        Information
      </div>
      </div>
    </div>
        <section className="order-form">
          {/* {!store.tadEns[0] ? ( */}

            <div className="d-flex flex-column">

              {store.docTypes[0].map(({id, tips, text, formname, port, checked, title}, idx) => (
                <ExludableDocumentRow
                  checked={checked}
                  title={title}
                  tips={tips}
                  documentType={formname}
                  documentDetails={text}
                  location={port}
                  onChange={(event) => setDocumentsByIndex(idx, {checked: event.target.value})}
                  show={true}
                />
              ))}
            </div>
         
        </section>
       
      </div>
      <div className="d-flex justify-content-between">
        <button className="blue-button-newload nl-save" onClick={handleClick} disabled = {diableSubmit}>
          <span>Submit load</span>
        </button>
        <div>
        </div>
      </div>
      {/* <ClientIDconfirmModal 
        show={showClientIDconfirmModal}
        onHide={() => {setShowClientIDconfirmModal(false)}}
        onConfirm={() => {setShowClientIDconfirmModal(false); props.submit(window.event)}}
        /> */}
      <ControlledGoodsModal
                    header="Controlled Goods"
                    size="md"
                    showButtons={true}
                    animation={false}
                    show={showControlledGoodsModal}
                    onHide={() => setShowControlledGoodsModal(false)}
                    subTitle={'As you have not selected "Controlled Goods" on the Load Header form, please confirm this by pressing NONE below; if this load does contain Controlled Goods, press CONTAINS to the return to the Load Header where you should select Yes from the dropdown.'}
                    onConfirm = {props.firstPage}
                    onNone={() => setNoneSelected(true)}
                  >
      </ControlledGoodsModal>
      
    </section>
    </div>
  );
};
export const DocumentTypeTooltip = ({title, tips}) => {
  if (tips !== '') {
    return (
      <OverlayTrigger
        placement="right"
        trigger={["hover", "focus"]}
        overlay={
          <Popover style={{ minWidth: "35rem", marginRight: "0.5rem !important" }}>
            <PopoverContent>
              <h6>
              {title}
              </h6>
              <p>
                {tips}
              </p>
            </PopoverContent>
          </Popover>
        }
      >
        <div className="info" style={{ width: "1rem", marginRight: "0.5rem" }}></div>
      </OverlayTrigger>
    );
  }
  else return (<div style={{ width: "1.5rem" }}></div>)

};

const ExludableDocumentRow = ({checked, tips, title, onChange, inputName, formname, documentType, location, show, documentDetails}) => {

  if (!show) return null;

  return (
    <div className="d-flex flex-column mb-1" style={{backgroundColor: "#f0f4f9"}}>
      <div className="d-flex gap-2 align-items-center my-3">
      <div style={{maxWidth: '75px', minWidth: '75px'}}>
        <Switch
          checked={checked}
          onChange={onChange}
          name={inputName}
          className="d-flex gap-3 text-eoriblue align-self-start px-2 ms-3"
        />
      </div>
      <Col xs="2"className="ms-3" style={{display: "flex", minWidth: "20rem"}}>
        <DocumentTypeTooltip
          title={title}
          tips={tips}
        />
        {title}
      </Col>
      <div style={{minWidth: "6.5rem"}}>
        {location}
      </div>
      <div className="ms-4">
        {documentDetails}
      </div>
      </div>
      <Collapse in={checked}>
        <div>
        <div className="d-flex gap-1 align-items-center mb-3" style={{marginLeft: "90px", height: "3em", display: "flex"}}>
        <span style={{marginLeft: "2.1rem",paddingTop: "10px", paddingBottom: "10px", fontSize: "1rem"}}>
          This document will be included when the load is submitted
          </span>
        </div>
        </div>
      </Collapse> 
    </div>
  )
}

export default OrderForm;
