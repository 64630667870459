import { useContext, useEffect } from "react";
import BPHeader from "./BPHeader";
import BPFooter from "./BPFooter";
import { useStepper } from "../Stepper";
import BPGrid from "./BPTestTable";

// Context
import { BusinessPartnerContext } from "./BusinessPartnerCard";

const BPCustomsAndCompliance = () => {
  const { activeStepKey } = useStepper();
  const {
    localBPData,
    handleAddData,
    isEditing,
    handleOnChange,
    handleFetchBusinessPartnerDropdownValues,
    errors,
    dropdownValues,
    setShowDocumentsListModal,
    setCurrentStep
  } = useContext(BusinessPartnerContext);

  useEffect(() => {
    const fetchData = async () => {
      handleFetchBusinessPartnerDropdownValues("Billable Customer");
      handleFetchBusinessPartnerDropdownValues("Deferment Authorised");
      handleFetchBusinessPartnerDropdownValues("Postponed VAT Accounting");
      handleFetchBusinessPartnerDropdownValues("CCG");
      //if activeStepkey is the current step we are in, then load the data into the context
      handleAddData("Authorisation Documents");
      handleAddData("Customs Authorisations");
    };
    if (activeStepKey === "BPCustomsAndCompliance") {
      //if activeStepkey is the current step we are in, then load the data into the context
      fetchData();
      setCurrentStep("BPCustomsAndCompliance");
    }
  }, [activeStepKey]);

  const renderData = (title) => {
    const data = localBPData[title];
    if (!data) {
      return <BPGrid.Row title="Loading..." />;
    } else {
      return data.map((item, index) => {
        //set hasError to true if errors?.[item?.subtitle] exists
        const hasError = errors?.[item?.subtitle] ? true : false;
        return (
          <BPGrid.Row
            title={index === 0 ? title : ""}
            subTitle={item.subtitle}
            value={item.value}
            isEditingValue={isEditing}
            valueType={item.Type}
            dropdownValues={dropdownValues?.[item?.subtitle]}
            onChange={handleOnChange}
            tableTitle={title}
            hasError={hasError}
            errorMessage={errors?.[item?.subtitle]}
            setModalShow={setShowDocumentsListModal}
          />
        );
      });
    }
  };

  return (
    <>
      <section className="bp-customscompliance">
        <BPHeader />
        <div className="bp-section-heading">Authorisation Documents</div>
        <BPGrid numCols={2} isEditing={isEditing}>
          {renderData("Authorisation Documents")}
        </BPGrid>

        <div className="bp-section-heading">Customs Authorisations</div>
        <BPGrid numCols={2} isEditing={isEditing}>
          {renderData("Customs Authorisations")}
        </BPGrid>
      </section>
      <BPFooter />
    </>
  );
};

export default BPCustomsAndCompliance;
